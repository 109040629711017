const PORTUGUESE_STRINGS = {
  analytics: 'Análises',
  analytic: 'Análise',
  batches: 'Lotes',
  controls: 'Controles',
  createAccount: 'Criar uma conta',
  createUser: 'Criar usuário',
  featureFlags: 'Sinalizadores de recursos',
  indexSets: 'Conjuntos de índice',
  labApp: 'Aplicativo de laboratório',
  logout: 'Sair',
  manageAgencies: 'Gerenciar Clusters',
  manageAgency: 'Gerenciar Cluster',
  manageUsers: 'Gerenciar usuários',
  myAccounts: 'Minhas contas',
  orders: 'Ordens',
  payments: 'Pagamentos',
  plates: 'Pratos',
  prep: 'Preparação',
  recommendationSets: 'Conjuntos de Médio',
  results: 'Resultados',
  rdAttributes: 'Atribuições de P&D',
  rdProtocols: 'Protocolos de P&D',
  samplePricing: 'Preço de amostra',
  samples: 'Amostras',
  sequencedData: 'Dados sequenciados',
  settings: 'Configurações',
  shippingLabels: 'Etiquetas de remessa',
  viewDemo: 'Demonstração',
  webApp: 'aplicativo web',
  searchAccount: 'Procure por uma conta',
  enterAccountName: 'Digite o nome da conta',
  viewDemoAccount: 'Visualize conta de demonstração',
  orYouCan: 'Ou você pode',
  viewAllAccounts: 'Veja todas as contas',
  viewMyAccounts: 'Veja minhas contas',
  searchByUserAgency: 'Pesquise por usuário/agência',
  searchByUser: 'Pesquise por usuário',
  demoAccount: 'Conta de demonstração',
  manageOrders: 'Gerenciar pedidos',
  viewResults: 'Ver resultados',
  editAccount: 'Editar conta',
  activeSamplingPlans: 'Planos de amostragem ativos (hectares)',
  accountName: 'Nome da conta',
  fields: 'Talhões',
  field: 'Talhão',
  unassignedPlans: 'Planos não atribuídos',
  waitingToSample: 'Esperando para provar',
  readyToSample: 'Pronto para provar',
  ordered: 'Ordenado',
  notOrdered: 'Não encomendado',
  created: 'Criada',
  sampled: 'Amostrado',
  received: 'Recebido',
  arrived: 'Chegado',
  sterilized: 'Esterilizado',
  partial: 'Parcial',
  shipped: 'Enviado',
  completed: 'Concluído',
  inProgress: 'Em andamento',
  notStarted: 'não foi iniciado',
  complete: 'Completo',
  cancelled: 'Cancelado',
  unassigned: 'Não atribuído',
  processed: 'Processado',
  wontSample: 'Não vai amostrar',
  partialResults: 'Resultados parciais',
  completedResults: 'Resultados concluídos',
  pressure: 'Pressão',
  pressurePanel: 'Painel de pressão',
  pressureNutrientPanel: 'Painel de pressão + Painel de nutrientes',
  performance: 'Desempenho',
  performancePanel: 'Painel de desempenho',
  perfPanel: 'Painel de perf',
  nutrientPanel: 'Painel de nutrientes',
  nitratePanel: 'Painel de nitratos',
  dashboard: 'Painel',
  rootworm: 'ROOTWORM',
  scn: 'Scn',
  analysis: 'Análise',
  eta: 'ETA',
  pending: 'Pendente',
  none: 'Nenhum',
  actions: 'Ações',
  allAccounts: 'Todas as contas',
  mapFields: 'Campos de mapa',
  uploadBoundaries: 'Carregar limites',
  manageAccount: 'Gerenciar conta',
  markPlanDelivered: 'Marcos planos entregues',
  setupASamplePlan:
    'Para configurar um plano de amostra, você precisa mapear os limites do campo. Você pode fazer isso de várias maneiras:',
  drawBoundariesOnMap: 'Desenhe limites em um mapa',
  uploadShapefilesData: 'Carregar shapfiles ou dados de plantio / colheita',
  mapFieldsToOrder: 'Mapear campos para fazer um pedido',
  toOrderFirstPlan: 'Para encomendar seu primeiro plano de amostra, clique',
  orderSamplePlan: 'Plano de amostra de pedido',
  orderAdditionalSamplePlan: 'Adicionar plano de amostra adicional',
  orderSample: 'Requisitar amostra',
  cancelSample: 'Cancelar amostra',
  editSamplePlan: 'Editar plano de amostra',
  belowForMappedFields: 'Abaixo para qualquer um dos seus campos mapeados:',
  samplePlan: 'Plano de amostra',
  status: 'Status',
  samplePlanCancelledSuccess: 'Plano de amostragem cancelado!',
  errorCancellingPlan:
    'Erro cancelamento de plano de amostragem. Por favor, tente novamente mais tarde.',
  shapefileDownloadSuccess: 'O download do Shapefile foi concluído com sucesso',
  errorDownloadingShpfile: 'Erro do download do shapfile. Tente mais tarde.',
  markNotReady: 'Não está pronto',
  sureMarkNotReady: "Tem certeza de que deseja marcar o plano 'como não concluído'?",
  showPastPlans: 'Mostrar planos de amostra passados',
  editFieldBoundary: 'Editar o limite do campo',
  editFieldBoundaries: 'Editar limites do campo',
  downloadShpfile: 'Faça o download do Shapefile',
  viewSampleStatus: 'Ver status da amostra',
  errorMarkingPlanDelivered:
    'Erro marcação de planos de amostragem conforme entregue. Tente novamente.',
  deliveryComplete: 'Entrega completa para',
  deliverCompletePar:
    'Esta operação possui planos de amostragem com resultados que ainda não foram marcados como entregues ao cliente. Se você concluiu a entrega desses resultados, poderá marcar esta operação como concluída agora.',
  cancel: 'Cancelar',
  loading: 'Carregando',
  markAsDelivered: 'Mark conforme entregue',
  orderSamplingPlan: 'Plano de amostragem de pedidos',
  assignTo: 'Atribuir a',
  markReady: 'Mark pronto',
  markReadyMsg: 'Clicar neste botão marcará este campo como Pronto para amostra',
  reassign: 'Reatribuição',
  assign: 'Atribuir',
  assignSampler: 'Atribua amostrador',
  state: 'Estado',
  assignSamplerPar: 'Selecione o amostrador que você gostaria de atribuir a este plano de amostra',
  searchSampler: 'Pesquise amostrador',
  assignAllPlans: 'Atribua todos os planos de amostra abertos nesta conta a este amostrador',
  inviteSampler: 'Convide Sampler',
  fieldName: 'Nome do campo',
  create: 'Criar',
  edit: 'Editar',
  fieldAlreadyExists: 'Um campo com esse nome já foi criado para sua operação.',
  save: 'Salvar',
  recenter: 'Recente',
  mapSearchPlaceholder: 'Pesquisar cidade, condado etc.',
  drawPolygon: 'Desenhe polígono',
  drawRectangle: 'Desenhe retângulo',
  delete: 'Excluir',
  downloadRunSheet: 'Download da folha de execução',
  runSheetDownloadFailed: 'Falha ao baixar a folha de execução. Tente mais tarde.',
  invalidFile: 'Arquivo inválido enviado',
  uploadResults: 'Upload resultados',
  uploadCsv: 'Carregue o arquivo CSV',
  backToResults: 'De volta aos resultados',
  print: 'Imprimir',
  shapefileDownloadMsg:
    'Isso pode levar 20 minutos ou mais. Sinta -se à vontade para sair da página. (O link aparecerá no download suspenso, quando disponível.)',
  download: 'Download',
  downloadCsvResults: 'Baixe os resultados do CSV',
  printSummary: 'Resumo da impressão',
  generateShapefileResults: 'Gerar resultados de shapefile',
  generateMapbookResults: 'Gerar resultados de mapa',
  legend: 'Legenda',
  highRisk: 'Alto risco',
  moderateRisk: 'Risco moderado',
  modRisk: 'Risco Mod.',
  lowRisk: 'Baixo risco',
  awaitingResults: 'Aguardando resultados',
  notAnalyzed: 'Não analisado',
  noData: 'Sem dados',
  'crop-protection': 'Doenças de Solo',
  bioactive: 'Biofertilidade',
  nutrients: 'Nutrientes',
  bcsr: 'Saturação de Base',
  rx: 'Recomendação',
  beneficialBioMarkers: 'Biomarcadores benéficos (mais alto é melhor)',
  harmfulBiomarkers: 'Biomarcadores nocivos (mais baixo é melhor)',
  macronutrients: 'Macronutrientes',
  micronutrients: 'Micronutrientes',
  cationRatios: 'Taxas de cátions',
  viewDetails: 'Ver detalhes',
  avg: 'Média',
  range: 'Amplitude',
  acAtRisk: 'HA em risco',
  ranges: 'Faixas',
  low: 'Baixo',
  mod: 'Médio',
  moderate: 'Moderado',
  high: 'Alto',
  recommendation: 'Recomendação',
  risk: 'Risco',
  createNutrientScript: 'Crie um script de nutrientes',
  remove: 'Remover',
  nutrient: 'Nutriente',
  plannedCrop: 'Colheita planejada',
  input: 'Parâmetro',
  formula: 'Fórmula',
  fieldRate: 'Taxa de campo',
  totalApplied: 'Total aplicado',
  targetYield: 'Produtividade alvo',
  fieldCost: 'Custo de campo',
  minimumRate: 'Doagem Mínima',
  maximumRate: 'Dosagem Máxima',
  rxName: 'Nome da médio',
  generateRxFrom: 'Gerar médio de',
  createAndEditRx: 'Criar reocumendaçao de nutrientes',
  createNutrientRx: 'Crie rx nutriente',
  backToFieldSummary: 'Voltar ao resumo do campo',
  allResults: 'Todos os resultados',
  nutrientRx: 'Médio de nutrientes',
  prescriptionCreatedMsg: 'Prescrição criada!',
  summary: 'Resumo',
  fieldAvg: 'Campo média',
  zoneDetails: 'Detalhes da zona',
  zonesAtRisk: 'zonas em risco',
  fieldComparison: 'Comparação de campo',
  analyticDetails: 'Detalhes analíticos',
  rating: 'Avaliação',
  zone: 'Zona',
  ele: 'Ele',
  average: 'Média',
  level: 'Nível',
  levels: 'Níveis',
  noUnits: 'Sem unidades',
  fieldsLower: 'Campos)',
  ranging: 'variando',
  yieldImpact: 'Rendimento de impacto',
  effectOfEnvironment: 'Efeito do ambiente',
  filename: 'Nome do arquivo',
  notes: 'Notas',
  createdAt: 'Criado em',
  sampleRisk: 'Risco de amostra',
  insufficientDataFound: 'Dados insuficientes encontrados',
  perSampleIndicates: 'por amostra indica',
  optimal: 'Ótimo',
  benchmark: 'Benchmark',
  sample: 'Amostra',
  levelsIndicateModerateRisk: 'Os níveis indicam risco moderado',
  levelsIndicateHighRisk: 'Os níveis indicam maior risco',
  levelsIndicateYieldLoss: 'Os níveis indicam risco de perda de rendimento',
  highRiskRwFieldTooltip:
    'A detecção de mais de 6 ovos indica alto risco de alimentação com raízes. Provavelmente varia de 0,5-2,5 lesão de nós sem proteção.',
  moderateRiskRwFieldTooltip:
    'A detecção de 0,2-6 ovos indica risco moderado de danos econômicos. Provavelmente varia de 0,25-0,7 lesão de nós sem proteção.',
  moderateRiskRwZoneTooltip:
    'A detecção de 0-6 ovos indica um risco moderado de danos econômicos. Provavelmente varia de 0,25-0,7 lesão de nós sem proteção.',
  lowRiskRwFieldTooltip:
    'A detecção de menos de 0,2 ovos indica baixo risco de danos econômicos. Provavelmente menos de 0,25 lesão de nós.',
  lowRiskRwZoneTooltip:
    'Nenhuma detecção de ovos indica baixo risco de danos econômicos. Provavelmente menos de 0,25 lesão de nós.',
  createFarmAccount: 'Crie uma nova conta agrícola',
  whatsTheNameOfAccount: 'Qual é o nome da conta agrícola?',
  whatsTheNameOfAccountTooltip: 'Isso pode ser um nome de fazenda, ou um indivíduo.',
  boundariesAndData: 'Limites e dados',
  twoWaysToUpload: 'Duas maneiras de fazer upload de limites e dados de campo',
  connectOutsideAccounts: 'Conectar contas externas',
  uploadShapefiles: 'Carreguefiles de Shapes',
  uploadShapefilesOfYourFields:
    'Faça o upload de shapfiles de seus campos aqui - certifique -se de incluir todos os arquivos (.shp, .shx, .dbf, etc/) e agrupe esses arquivos em um único arquivo zip',
  uploadZipFile: 'Faça o upload do arquivo zip',
  uploadZipTip: 'O arquivo carregado deve estar em um formato .zip ou .gz.',
  completeIngest: 'Ingestão completa',
  discard: 'Descartar',
  cancelIngest: 'Cancelar ingestão',
  pastFileUpload: 'Upload de arquivos anteriores',
  upload: 'Carregar',
  connectOutsideAccountsImport:
    'Conecte -se a contas externas para importar facilmente limites de campo e camadas de dados em padrão AG',
  serverErrorMsg: 'Ocorreu um erro com o servidor, atualize e tente novamente.',
  patternIsSyncing:
    'O Pattern AG está sincronizando com sua conta. (Isso pode levar alguns minutos)',
  pleaseRefreshUnableToConnect:
    'Por favor, atualize e tente novamente. Não conseguimos conectá -lo a',
  lastSync: 'Última sincronização',
  notSyncedYet: 'Ainda não está sincronizado',
  connecting: 'Conectando',
  connect: 'Conectar',
  connectYourDataFrom: 'Conecte seus dados de',
  role: 'Papel',
  admin: 'Admin',
  operator: 'Operador',
  sampler: 'Amostrador',
  lab: 'Laboratório',
  superadmin: 'Super Admin',
  agent: 'Agente',
  agency_admin: 'Administrador da Cluster',
  businessName: 'Nome da empresa',
  firstName: 'Primeiro nome',
  lastName: 'Sobrenome',
  email: 'E-mail',
  cellPhone: 'Celular',
  billingAddress: 'Endereço de Cobrança',
  city: 'Cidade',
  zipcode: 'CEP',
  excludeContractSignature: 'Excluir a assinatura do contrato?',
  isThisPartOfExisting: 'Isso faz parte de um Cluster existente?',
  yes: 'Sim',
  noWeNeedToCreate: 'Não, precisamos criar uma nova agência para eles.',
  agentsMustBeAssignedToExisting:
    'Os agentes devem ser atribuídos a uma agência existente. Procure a agência abaixo. Se você deseja criar uma nova agência, precisará atribuir o papel de administração da agência a este usuário',
  userWillBeAssignedTip:
    "Nota: O usuário criado será atribuído como o 'agente principal' para esta agência, o que significa que suas informações de contato serão usadas para fins de cobrança.",
  agencyName: 'Nome da Agência',
  searchAgencyName: 'Nome da agência de pesquisa',
  thisFieldIsRequired: 'Este campo é obrigatório.',
  enterValidEmail: 'Digite um endereço de e-mail válido.',
  enterValidPhone: 'Digite um número de telefone válido.',
  enterAgencyName: 'Digite um nome de agência.',
  addAnAgency: 'Por favor, adicione uma agência.',
  searchUsers: 'Pesquise usuários',
  agency: 'Agência',
  searchAgency: 'Agência de pesquisa',
  agents: 'Agentes',
  primaryAgent: 'Agente primário',
  phone: 'Telefone',
  searchPayments: 'Pagamentos de pesquisa',
  user: 'Do utilizador',
  purchase: 'Compras',
  earnings: 'Ganhos',
  other: 'Outro',
  balance: 'Equilíbrio',
  open: 'Abrir',
  approved: 'Aprovado',
  youHaveNoApprovedMsg:
    "Você não tem pagamentos 'aprovados'. Para visualizar pagamentos 'abertos', clique no seletor 'aberto' acima.",
  payorPayee: 'Pagador / beneficiário',
  type: 'Tipo',
  amount: 'Quantia',
  updated: 'Atualizada',
  invoice: 'Fatura',
  payment_due: 'Pagamento devido',
  settled: 'Assentou',
  payment: 'Pagamento',
  markSettled: 'Mark se estabeleceu',
  lineItemDetail: 'Detalhes do item de linha',
  adjustments: 'Ajustes',
  name: 'Nome',
  approvedBy: 'Aprovado por',
  recordedBy: 'Gravado por',
  operation: 'Operação',
  acres: 'Hectares',
  purchases: 'Compras',
  netPayment: 'Pagamento líquido',
  markSettledMsg:
    'Ao marcar esse pagamento como liquidado, você sabe que esse pagamento foi inserido nos registros contábeis da Pattern AG e foi tratado de acordo com os detalhes descritos abaixo:',
  markRecorded: 'Mark gravado',
  approveOpenTransactions: 'Aprovar transações abertas',
  loginToQuickbooks: 'Faça login no QuickBooks',
  openTransactions: 'Transações abertas',
  activityDetails: 'Detalhes da atividade',
  successfullyCreatedInvoice: 'Criou com sucesso a fatura no QuickBooks.',
  successfullyCreatedNoQB: 'Criou com sucesso a fatura sem enviá -la para o QuickBooks.',
  errorApprovingTransaction: 'Ocorreu um erro ao tentar aprovar a transação.',
  errorLoggingIn: 'Erro obtendo URL de login. Pode haver um problema de back -end.',
  billingAddressRequired: 'Endereço de cobrança necessário.',
  addAdjustments: 'Adicionar ajustes',
  approveTransaction: 'Aprovar a transação',
  transactionApprovalMsg:
    'Ao aprovar essas transações, eles serão enviados para contabilizar o processamento.',
  resultInPatternPaymentMsg: 'Isso resultará em Pattern AG fazendo o pagamento para',
  resultInPatternInvoiceMsg: 'Isso resultará no faturamento do padrão AG',
  addAdjustmentsFor: 'Adicione ajuste para',
  description: 'Descrição',
  reasonForAdjustments: 'Qual é o motivo do ajuste?',
  adjustmentAmount: 'Quantidade de ajuste',
  paysPatternAg: 'Paga o padrão AG',
  patternAgPays: 'Pattern AG paga',
  addAdjustment: 'Adicione ajuste',
  shippingLabelsInProgress: 'Rótulos de remessa em andamento',
  createNewLabel: 'Crie nova etiqueta de remessa',
  samplePlans: 'Planos de amostra',
  labels: 'Etiquetas',
  operations: 'Operações',
  printAll: 'Imprimir Tudo',
  lastUpdated: 'Ultima atualização',
  location: 'Localização',
  printLabel: 'Etiqueta de impressão',
  trackPackage: 'Pacote de rastreamento',
  viewEditLabel: 'Rótulo de visualização/edição',
  cancelLabel: 'Cancelar rótulo',
  shippingLabel: 'Etiqueta de remessa',
  affectAllLabelsMsg: 'Isso afetará todos os rótulos deste grupo.',
  shippingFrom: 'Frete from',
  addAddress: 'Adicione endereço',
  parcelDimensions: 'Dimensões de encomendas',
  length: 'Comprimento',
  width: 'Largura',
  height: 'Altura',
  weight: 'Peso',
  samplePlansAwaiting: 'Planos de amostra aguardando remessa',
  searchByAccount: 'Pesquise por conta',
  account: 'Conta',
  inShipment: 'Em remessa',
  createShippingLabels: 'Crie rótulos de remessa',
  createShippingAddress: 'Crie endereço de entrega',
  street: 'Rua',
  addressCreatedMsg: 'Endereço atualizado com sucesso!',
  numberOfLabels: 'Número de etiquetas',
  fillOutRequiredFields: 'Por favor, preencha todos os campos necessários antes de enviar.',
  contactInfoUpdated: 'Informações de contato atualizadas!',
  errorContactInfo: 'Erro ao atualizar as informações de contato.',
  billingInfoUpdated: 'Informações de cobrança atualizadas!',
  errorBillingInfo: 'Erro ao atualizar informações de cobrança.',
  profileSettings: 'Configurações de perfil',
  contactInfo: 'Informações de contato',
  changePassword: 'Alterar a senha',
  errorPasswordChange: 'Falha na mudança de senha.',
  incorrectPassword: 'A senha antiga que você inseriu está incorreta.',
  oldPassMissing: 'A senha antiga está faltando.',
  newPassMissing: 'Nova senha está faltando.',
  newPassConfMissing: 'Falta nova confirmação de senha.',
  existingPassNoReuse: 'A senha existente não pode ser reutilizada.',
  errorNewPassRules: 'A nova senha fornecida não corresponde às regras de senha abaixo.',
  errorNewPassConf: 'A nova confirmação de senha não corresponde à nova senha.',
  update: 'Atualizar',
  password: 'Senha',
  oldPassword: 'Senha Antiga',
  newPassword: 'Nova Senha',
  passwordRules: 'Nota: deve conter uma letra de maiúsculas, letra minúscula e um número',
  newPassConf: 'Nova confirmação de senha',
  saveBillingInfo: 'Salvar informações de cobrança',
  invalidZipcode: 'CEP inválido',
  signIn: 'Entrar',
  authFailed: 'Autenticação falhou',
  forgotPassword: 'Esqueceu sua senha?',
  requestFailed: 'Solicitação falhou. Por favor, tente novamente mais tarde.',
  emailAddress: 'Endereço de email',
  willReceiveLinkMsg: 'Você receberá um e -mail com um link para redefinir sua senha',
  sendRequestInstructions: 'Envie instruções de redefinição',
  analysisPackage: 'Pacote de análise',
  zones: 'Zonas',
  per: 'Por',
  sampleExecution: 'Execução de amostra',
  sampledBy: 'Amostrado por',
  samplingTiming: 'Timing de amostragem',
  planConfMsg:
    "Ao clicar em 'Confirmar Ordem', você concorda em comprar os serviços descritos acima para esta operação. O pagamento será devido 2 semanas após a disponibilização dos resultados.",
  markedReadyAssignedMsg: 'Quando marcado pronto, um amostrador será atribuído',
  confirmOrder: 'Confirmar pedido',
  sampling: 'Amostragem',
  samplePlanDetails: 'Detalhes do plano de amostra',
  samplingZones: 'Zonas de amostragem',
  assignSamplePlan: 'Atribuir um plano de amostra',
  confirmAnalysis: 'Confirme a análise',
  planZonesSuccessMsg: 'As zonas foram atualizadas com sucesso!',
  planZonesErrorDiffMsg: 'Você pode ter enviado um campo diferente.',
  planZonesErrorNoZonesMsg: 'Este upload não contém zonas.',
  planZonesErrorNoZipMsg:
    'Você deve fazer upload de um arquivo zippeado contendo um arquivo ".shp" ssurgo',
  ssurgo: 'Tipo de solo (SSURGO)',
  grid_zones: 'Zonas de grade',
  grid_points: 'Pontos de grade',
  upload_zones: 'Zonas de upload',
  custom_points: 'Pontos personalizados',
  acresPerZone: 'Hectares por zona',
  generating: 'Gerando',
  reselectDensity: 'Reselecionar a densidade',
  uploading: 'Enviando',
  editZones: 'Zonas de edição',
  resetMap: 'Redefinir mapa',
  generateZones: 'Gerar zonas',
  uploadZones: 'Zonas de upload',
  lockZones: 'Zonas de bloqueio',
  noZonesSelected: 'Sem zonas selecionadas para análise',
  density: 'Densidade',
  by: 'por',
  noAnalysis: 'Sem análise',
  sampleTiming: 'Timing de amostra',
  allZones: 'Todas as zonas',
  nutrient_panel_description:
    'Painel Completo de Macro e Micronutrientes: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Matéria Orgânica',
  nutrient_panel_description_plus_nitrate:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter, além de testes de Nitrato',
  restricted_panel_description:
    'A análise de nutrientes não está disponível para solo restrito neste momento',
  pressure_panel_description:
    'Preveja e planeje o nematóide do nó da raiz e o nematodo do cisto de soja, medindo sua pressão antes de plantar',
  pressure_panel_description_rkn:
    'Preveja e planeje o Nematóide das Galhas, o Nematóide do Cisto da Soja e a Síndrome da Morte Súbita medindo sua pressão antes do plantio',
  performance_panel_description:
    'Mapeie o risco de doença do solo e a oportunidade de biofertilidade, para otimizar os gastos com insumos',
  biological_analysis: 'Análise Biológica',
  nutrient_analysis: 'Análise de Nutrientes',
  splitDensityForBio: 'Densidade dividida para análise biológica + de nutrientes',
  splitDensityDesc:
    'A densidade dividida permite executar a análise biológica em uma densidade mais baixa do que a análise de nutrientes. O painel de pressão e o painel de desempenho serão incluídos para quaisquer zonas biológicas selecionadas.',
  splitDensity: 'Densidade dividida',
  splitDensityBarcode: 'Código de barras de densidade dividida',
  order: 'Ordem',
  rotate: 'Girar',
  merge: 'Mesclar',
  mergeZones: 'Zonas de mesclagem',
  split: 'Dividir',
  splitZones: 'Zonas divididas',
  points: 'Pontos',
  deleteZonesSuccess: 'Zonas excluídas com sucesso!',
  splitZonesSuccess: 'Zonas divididas com sucesso!',
  splitEveryZoneError: 'Não foi possível dividir todas as zonas.',
  selectZonesToMerge: 'Selecione zonas para mesclar.',
  mergeZonesSuccess: 'Zonas mescladas com sucesso!',
  invalidZoneComboError: 'Combinação de zona inválida.',
  couldNotMergeSelectedError: 'Não foi possível mesclar zonas selecionadas.',
  unableToDisplayZoneGroupingError: 'Não é possível exibir agrupamentos de zona.',
  total: 'Total',
  noDiscount: 'Sem desconto',
  createZonesInOrderToBegin: 'Crie zonas para começar a selecionar a análise!',
  paymentTerms: 'Termos de pagamento',
  earliestSample: 'Amostra mais antiga',
  resultsDownloadSuccess: 'O download dos resultados foi concluído com sucesso.',
  resultsDownloadFailure: 'Erro ao baixar resultados. Tente mais tarde.',
  downloadResults: 'Baixar resultados',
  generateMapbookMsg:
    'Gerando mapa de mapas... Isso levará alguns minutos. O download aparecerá no menu suspenso.',
  regulatedSoil: 'Solo regulado',
  regulatedSoilMsg:
    'Se este plano de amostra contiver solo regulado, revise os protocolos necessários e verifique se você está concluindo cada etapa antes do envio',
  containsRegulatedSoilMsg: 'Contém solo regulado (inclua o número da DSCA)',
  failedToDownloadRegSoilMsg: 'Falha ao baixar o PDF regulamentado do solo.',
  failedGetRegulatedSoilMsg: 'Não é possível obter pdf do solo regulado.',
  successCreatedShippingLabelBatch:
    'Criou com sucesso o lote de etiquetas de remessa. Seu download estará pronto em alguns minutos.',
  errorCreatingShippingLabel: 'Erro ao criar o rótulo de remessa.',
  noResultsExistForOperation: 'Não existem resultados para esta operação.',
  averageAppliedToZone: 'Média de campo aplicada à zona',
  dscaInShipment:
    'As remessas de solo regulado também devem incluir o seguinte deslizamento de embalagem, que contém o número da DSCA da Pattern AG',
  editOperation: 'Operação de edição',
  operationDetails: 'Detalhes da operação',
  recommendationDefaults: 'Padrões de Médio',
  confirmFieldRemoval: 'Confirme a remoção de campo',
  removeFieldMsg:
    'Remover esse campo da sua operação significa que o campo e seus dados associados não estão mais disponíveis para você. Isto não pode ser desfeito.',
  confirmRemoveFieldMsg: 'Tem certeza de que deseja remover este campo?',
  invite: 'Convidar',
  inviteByEmail: 'Convide usuários por e -mail',
  enterAnEmail: 'Digite um email',
  excludeFromAnalysis: 'Excluir da análise',
  leaveOperation: 'Deixar operação',
  removeAccess: 'Remova o acesso',
  removeBillingResponsibility: 'Remova a responsabilidade de cobrança',
  assignedBillingMsg:
    'A responsabilidade de cobrança atribuída ao usuário não assinou seu contrato com o padrão. Eles podem acessar e assinar o contrato fazendo login na sua conta em www.pattern.app. Você poderá criar planos de amostra para esta conta, mas não poderá coletar amostras em campo até que o contrato seja assinado ou um usuário alternativo seja adicionado para cobrar responsabilidade.',
  contractStatus: 'Status do contrato',
  signed: 'Assinado',
  removeBillingPartyMsg:
    'Tem certeza de que deseja remover esse usuário da responsabilidade de cobrança por esta conta? Sem um usuário responsável pelo faturamento na conta, você não poderá coletar amostras no campo.',
  commission: 'Comissão',
  checkingForExistingPlan: 'Verificando um plano de amostragem existente',
  createNewAgent: 'Crie um novo agente',
  inviteAgentCreateAcctMsg:
    'Convide um agente para criar uma conta e trabalhar com os clientes da sua agência',
  thatEmailHasAcctMsg:
    'Esse email já tem uma conta com o Pattern AG. Use um email diferente ou suporte de contato para que esse usuário mova para esta agência.',
  updatedUserRole: 'Função de usuário atualizada!',
  backToAllRx: 'Voltar para todos os RX',
  from: 'De',
  prescriptionSaved: 'Prescrição salva',
  timing: 'Tempo',
  crop: 'Cultura',
  tillage: 'Lavoura',
  pricePerTon: 'Preço por tonelada',
  percentTargetApply: 'Porcentagem da meta para aplicar',
  expectedYield: 'Rendimento esperado',
  outputFormula: 'Formato de saída',
  export: 'Exportar',
  preplant: 'Pré-planta',
  starter: 'Iniciante',
  sidedress: 'Cobertura',
  foliar: 'Doenças Foliares',
  corn: 'Milho',
  soybeans: 'Soja',
  shapefile: 'Shapefile',
  conventionalTillage: 'Lavoura convencional',
  reducedNoTill: 'Reduzido / sem plantão',
  soilTest: 'Teste do solo',
  applied: 'Aplicado',
  removedFromPlan: 'Removido do plano',
  sampleCancelledSuccessMsg: 'A amostra foi cancelada com sucesso',
  sampleCancelledFailureMsg: 'Cancelar a amostra falhou',
  samplePlanStatus: 'Status do plano de amostra',
  sampleSummary: 'Resumo da amostra',
  sampleStatus: 'Status da amostra',
  totalReads: 'Leituras totais',
  action: 'Ação',
  cancelEntireSample: 'Cancelar amostra inteira',
  cancelAllAnalysis: 'Cancelar todas as análises',
  cancelNutrient: 'Cancelar Nutrientes',
  cancelNitrate: 'Cancelar Nitrato',
  cancelPressure: 'Cancelar Pressão',
  cancelPerformance: 'Cancelar o Desempenho',
  batchSummary: 'Resumo do lote',
  poolBarcode: 'Código de barras da piscina',
  samplesInPool: 'Amostras na piscina',
  batchPoolStatus: 'Status de lote/pool',
  noSamplesInBatch: 'Sem amostras em lote.',
  batch: 'Lote',
  batched: 'Em lote',
  plate: 'Placa',
  plated: 'Banhado',
  homogenization: 'Homogeneização',
  homogen: 'Homogênio',
  shotgun: 'Espingarda',
  ingested: 'Ingerido',
  processing: 'Em processamento',
  failedToFetchThresholdsMsg: 'Falhou em buscar limiares. Tente mais tarde.',
  readThresholdUpdateSuccessMsg: 'Leia a atualização do limite bem -sucedido',
  readThresholdUpdateFailureMsg: 'A atualização do limite de leitura falhou',
  cancelSamplePlanFailureMsg: 'Falha ao cancelar o plano de amostragem. Tente mais tarde.',
  samplePlanReadThreshold: 'Limite de leitura do plano de amostra',
  cancelSamplePlan: 'Cancelar o plano de amostra',
  cancelSamplingPlanMsg:
    'Ao cancelar esse plano de amostra, ele não poderá mais ser visível nesta operação.',
  sureCancelPlan: 'Tem certeza de que deseja cancelar este plano?',
  fourOFourMsg:
    'Parece que a página que você está procurando está vazia. Experimente um dos links acima ...',
  yourPasswordChanged: 'Sua senha foi atualizada!',
  login: 'Conecte-se',
  thanksForJoiningPatter: 'Obrigado por ingressar no padrão!',
  letsSetYourPassword: 'Vamos definir sua senha',
  setPassword: 'Configurar senha',
  passwordReqMsg: 'Deve conter 8 caracteres, incluindo uma letra em maiúsculas e um número',
  assignSamplerNoBilling:
    'Atribua uma responsabilidade de cobrança antes que o campo possa ser amostrado.',
  only: 'Apenas',
  no: 'Não',
  weighedAndGround: 'Pesado e terreno',
  userCreatedMsg: 'Usuário criado com sucesso!',
  errorLoggingInMsg: 'Havia um problema de login. Por favor, tente novamente.',
  errorLoggedInMsg: 'Seu token expirou. Por favor faça login novamente.',
  failedToFetchMsg: 'Falhou em buscar meta dados. Por favor atualize.',
  ssurgoOmDisclaimer:
    'No caso de uma zona estar faltando um valor para a OM, será usada uma média ponderada do survório de Ssurgo Soil.',
  csvDownloadMsg:
    'Isso pode levar alguns minutos. Sinta -se à vontade para sair da página. (O link aparecerá no download suspenso, quando disponível.)',
  generateCsvResults: 'Gerar resultados de CSV',
  prescriptions: 'Prescrições',
  successfullyRemovedPrescription: 'Removido com sucesso prescrição',
  searchSampleMsg: 'Procure uma amostra por código de barras ou UUID',
  searching: 'Procurando',
  enterBarcodeMsg: 'Digite código de barras ou uuid',
  errorDeletingPrescription: 'Ocorreu um erro ao excluir a prescrição. Por favor atualize.',
  receivingDataEntry: 'Recebimento e entrada de dados',
  fieldAndSamplePlanDetails: 'Detalhes do plano de campo e amostra',
  sampleBarcode: 'Amostra de código de barras',
  mass: 'Massa',
  markArrived: 'Mark chegou',
  markSterilized: 'Marca esterilizada',
  submit: 'Enviar',
  submitting: 'Envio',
  nitrate: 'Nitrato',
  ammoniumAcetate: 'Acetato de amónio',
  m3Rerun: 'M3 Renum',
  phRerun: 'pH Renum',
  kclRerun: 'KCL Renum',
  aaRerun: 'AA Renum',
  omRerun: 'OM Renum',
  shotgunQpcr: 'Espingarda + qPCR',
  primary: 'Primário',
  stateCounty: 'Estado / País',
  relatedRsms: 'RSMS relacionados',
  relatedAgencies: 'Agências relacionadas',
  nonPrimary: 'Não primário',
  createZonesUsing: 'Tipo de amostragem',
  restrictedGeography: 'Geografia restrita',
  restrictedGeographyFromMsg: 'Amostra é de',
  restrictedGeogrpahyProtocolsMsg: 'Siga protocolos de manuseio adequados',
  processingSummary: 'Resumo do processamento',
  qpcrAnalysis: 'Análise QPCR',
  shotgunAnalysis: 'Análise de espingarda',
  nutrientDetails: 'Detalhes dos nutrientes',
  dnaExtraction: 'Extração de DNA',
  dnaExtr: 'Extr',
  normalizedDna: 'DNA normalizado',
  normDna: 'DNA norma',
  m3Analysis: 'Análise M3',
  phAnalysis: 'Análise de pH',
  kclAnalysis: 'Análise de KCl',
  aaAnalysis: 'Análise de AA',
  ammoniumAcetateAnalysis: 'Análise de acetato de amônio',
  genomic: 'Genômico',
  amplicon: 'Amplicon',
  all: 'Todos',
  sampleNotFound: 'Amostra não encontrada',
  sampleNotFoundMsg:
    'Nenhuma instância de amostra que corresponda este código de barras foi encontrada',
  source: 'Fonte',
  chemistryResults: 'Resultados da química',
  noChemistryResultsMsg: 'Nenhum resultado de química para esta amostra UUID',
  tbd: 'TBD',
  failed: 'Fracassado',
  processingData: 'Processando dados',
  addSample: 'Adicione amostra',
  sampleMass: 'Massa de amostra',
  controlType: 'Tipo de controlo',
  selectOne: 'Selecione um',
  sampleType: 'Tipo de amostra',
  replicaType: 'Tipo de réplica',
  well: 'Bem',
  removeSample: 'Remova a amostra',
  barcode: 'Códigos de barras',
  barcodes: 'Códigos de barras',
  tags: 'Tag',
  awaitingWellInpuMsg: 'Aguardando a contribuição para bem',
  sampleAlreadyInWellMsg: 'A amostra já existe em outro poço, use uma replicação',
  longitude: 'Longitude',
  latitude: 'Latitude',
  samplingPlan: 'Plano de amostragem',
  sampleAlreadyExistsMsg:
    "Esse código de barras está associado a uma amostra existente. Se este for para uma amostra replicada, vá para 'Adicionar amostra'. Se você pretende criar uma nova amostra no sistema, escolha um código de barras diferente.",
  addControl: 'Adicione controle',
  createSample: 'Crie amostra',
  errorSavingOperationMsg: 'Operação de economia de erro. Por favor atualize',
  operationSavedMsg: 'Operação salva com sucesso!',
  errorSavingFieldMsg: 'Erro Salvando o campo. Por favor atualize.',
  fieldSavedMsg: 'Campo economizado com sucesso!',
  farm: 'Fazenda',
  newPlate: 'Nova placa',
  temp: 'Temp',
  freezer: 'Congelador',
  rack: 'Prateleira',
  shelf: 'Prateleira',
  storage: 'Armazenar',
  discarded: 'Descartado',
  plateType: 'Tipo de placa',
  newPlateBarcode: 'Novo código de barras de placa',
  scanNewBarcodeMsg: 'Digitalize o novo código de barras de placa',
  sourcePlateBarcode: 'Código de barras da placa de origem',
  scanBarcodeMsg: 'Digitalize o código de barras da placa',
  quadrant: 'Quadrante',
  indexSet: 'Conjunto de índice',
  target: 'Alvo',
  plateBarcode: 'Código de barras da placa',
  noPlatesFoundMsg: 'Nenhuma placa encontrada',
  searchByBarcode: 'Pesquise por código de barras',
  comments: 'Comentários',
  requiredField: 'Campo obrigatório',
  createPlate: 'Crie placa',
  fieldDeletedMsg: 'Campo excluído com sucesso!',
  cancelAll: 'Cancelar tudo',
  failedDeleteLabelsMsg: 'Falhou em excluir rótulos. Por favor, atualize e tente novamente.',
  labelDeletedSuccessMsg: 'Etiquetas excluídas com sucesso!',
  deleteLabelsMsg: 'Tem certeza de que deseja excluir este rótulo?',
  deleteLabelsGroupMsg: 'Tem certeza de que deseja excluir este grupo de etiquetas?',
  label: 'Rótulo',
  tooManyLabelsMsg: 'Por favor, gerar menos de 80 rótulos de cada vez.',
  labId: 'ID do laboratório',
  generic: 'Genérico',
  dnaConcentration: 'Concentração de DNA',
  dilutionConcentration: 'Concentração de endro',
  cycleTreshold: 'Limiar de ciclo',
  uploadFailedMsg: 'Falha no upload.',
  uploadSuccessMsg: 'Carregue bem -sucedido!',
  relatedDocuments: 'Documentos relacionados',
  uploadedResults: 'Resultados enviados',
  provideNotesMsg: 'Forneça notas para o documento enviado',
  selectFileToUploadMsg: 'Selecione um arquivo para fazer upload',
  noFileChosen: 'Nenhum arquivo selecionado',
  uploadDocument: 'Documento de upload',
  missingUrlMsg: 'Falta upload_url',
  summarySheet: 'Folha de resumo',
  parentPlates: 'Placas parentais',
  childrenPlates: 'Placas de crianças',
  plateDetails: 'Detalhes da placa',
  updateData: 'Atualizar dados',
  exportData: 'Exportar dados',
  importData: 'Importar dados',
  dnaConcentrationData: 'Dados de concentração de DNA',
  targetDilution: 'Diluição alvo',
  totalDnaMultiplier: 'Multiplicador total de DNA',
  pcrDnaMultiplier: 'Multiplicador de DNA de PCR',
  recalculate: 'Recalcular',
  updateConcentrationDataMsg:
    'Já existem dados de concentração associados a esta placa. Se você enviar um novo arquivo, ele substituirá os dados de concentração associados a cada poço. Você tem certeza que quer continuar?',
  uploadSamples: 'Carregar amostras',
  uploadSamplesCsvMsg: 'Carregue poços de amostra do arquivo CSV',
  uploadProgress: 'Carregar progresso',
  doNotNavigateMsg: 'Não navegue para longe desta página até que o upload seja concluído.',
  uploadAbortedMsg: 'O upload do arquivo foi abortado.',
  uploadErrorMsg: 'Houve um erro de carregamento de erro.',
  abort: 'Abortar',
  noFilesListMsg: 'Nenhum arquivo a ser exibido.',
  noPlatesListMsg: 'Sem placas para exibir.',
  confirm: 'confirme',
  copyPlate: 'Copiar placa',
  uploadBulkPlate: 'Carregue a placa a granel',
  openPlate: 'Placa aberta',
  storePlate: 'Placa da loja',
  downloadDetails: 'Baixar detalhes',
  discardPlate: 'Descarte a placa',
  dlSampleSheet: 'Folha de amostra de DL',
  discardPlateWarningMsg:
    'Aviso: se você descartar esta placa, ela não poderá ser recuperada. Você tem certeza de que quer fazer isso?',
  sourcePlates: 'Placas de origem',
  qpcrCycles: 'Ciclos QPCR',
  editQpcrCycles: 'Editar ciclos qPCR',
  editNotes: 'Editar notas',
  indicatePlateStorageMsg: 'Indique onde e como a placa será armazenada',
  temperature: 'Temperatura',
  coldOrRoomTemp: 'Temperatura fria ou da sala',
  unableToSavePlanMsg: 'Incapaz de salvar o plano. Por favor, atualize e tente novamente.',
  errorWithFieldGeometryMsg:
    'Please check your field boundaries. There may be unnecessary intersections or holes.',
  unableToLoadZonesMsg: 'Unable to load zones. Try again later.',
  errorLoadingExistingPlanMsg:
    'Havia um problema carregando o plano existente. Por favor atualize.',
  cancelSamplePlanMsg: 'Tem certeza de que deseja cancelar este pedido?',
  samplePlanTracking: 'Rastreamento de plano de amostra',
  planStatus: 'Status do plano',
  daysMarkedReady: 'Dias marcados prontos',
  alerts: 'Alertas',
  readiness: 'Prontidão',
  unassigned_ready_to_sample: 'Não atribuído, pronto para provar',
  assignment_not_accepted: 'Tarefa não aceita',
  assignment_declined: 'A tarefa recusou',
  marked_ready: 'Marcado pronto',
  sampling_at_risk: 'Amostragem em risco',
  sampling_overdue: 'Amostragem vencida',
  sampled_not_shipped: 'Amostrado, não enviado',
  sla_at_risk: 'SLA em risco',
  sla_overdue: 'SLA vencido',
  insufficient_data: 'Dados insuficientes',
  'partially sampled': 'Parcialmente amostrado',
  'partially shipped': 'enviado parcialmente',
  'partially received': 'Recebido parcialmente',
  ready: 'Preparar',
  assigned_not_accepted: 'Atribuído, não aceito',
  accepted: 'Aceitaram',
  declined: 'Recusou',
  not_ready: 'Não está pronto',
  assignment_accepted: 'Atribuição aceita',
  marked_ready_days: 'Marcado pronto (dias)',
  all_statuses: 'Todos os status',
  all_assignments: 'Todas as tarefas',
  all_readiness: 'Toda a prontidão',
  all_alerts: 'Todos os alertas',
  panel: 'Painel',
  hideDetails: 'Detalhes ocultos',
  goToPlan: 'Vá para planejar',
  genomicProcessing: 'Processamento genômico',
  nutrientProcessing: 'Processamento de nutrientes',
  newBatch: 'Novo lote',
  noBatchesFound: 'Nenhum lotes encontrados',
  plateNotAddedToBatch: 'Placa não adicionada ao lote',
  plateAddedToBatch: 'Placa adicionada ao lote',
  showMe: 'Mostre-me',
  with: 'Com',
  plateBarcodeId: 'Código de barras de placa / id',
  allAnalyses: 'Todas as análises',
  allTypes: 'Todos os tipos',
  allStatuses: 'Todos os status',
  homogenBarcode: 'Homogen Barcode',
  createBatch: 'Crie lote',
  soil: 'Solo',
  residue: 'Resíduo',
  rootBall: 'Bola da raiz',
  rhizosphere: 'Rizosfera',
  rootTissue: 'Tecido radicular',
  isolates: 'Isolados',
  partiallyShipped: 'enviado parcialmente',
  addPlate: 'Adicione a placa',
  totalDna: 'DNA total',
  totalPcrDna: 'DNA total de PCR',
  'waiting to sample': 'Esperando para provar',
  markNotReadySuccess: 'O plano foi marcado com sucesso como não está pronto.',
  markNotReadyFail:
    'Houve um erro marcando o plano como não está pronto. Por favor, atualize e tente novamente.',
  assignSamplerError:
    'Houve um erro de atribuição do amostrador. Por favor, atualize e tente novamente.',
  updatingAgencyErrorMsg:
    'Houve um erro atualizando a agência. Por favor, atualize e tente novamente.',
  updatingAgencySuccessMsg: 'A agência foi atualizada com sucesso!',
  emailAlreadyExistsMsg:
    'Esse email já tem uma conta com o Pattern AG. Use um email diferente ou suporte de contato para que esse usuário mova para esta agência.',
  updatingUserRoleErrorMsg:
    'Houve um erro atualizando a função do usuário. Por favor, atualize e tente novamente.',
  addPlateSuccess: 'Plate adicionado com sucesso!',
  sourcePools: 'Pools de origem',
  sampleSheet: 'Folha de amostra',
  markBatchSent: 'Mark Batch Sent',
  dlNovaSeqIndexSets: 'Conjuntos de índice DL NovaSeq',
  dlMiSeqIndexSets: 'Conjuntos de índice DL MiSeq',
  dlRunSheet: 'Folha de execução DL',
  markBatchWarningMsg:
    'Observe que este lote não será mais editável e as folhas de amostra serão trancadas!',
  markBatchConfirmMsg: 'Tem certeza de que deseja marcar este lote como enviado?',
  contractPricing: 'Preço de contrato',
  contractDetails: 'Detalhes do contrato',
  priceList: 'Lista de preços',
  agencyCost: 'Custo de agencia',
  listPrice: 'Preço de tabela',
  msrp: 'MSRP',
  pressure_panel: 'Painel de pressão',
  performance_panel: 'Painel de desempenho',
  nutrient_panel: 'Painel de nutrientes',
  createContract: 'Criar contrato',
  createNewContract: 'Criar novo contrato',
  list: 'Lista',
  editContract: 'Editar contrato',
  support: 'Apoiar',
  deleteYourDataMsg:
    'Para obter suporte ou solicitar a exclusão de sua conta e/ou dados, envie um email para support@pattern.ag.',
  contractUpdatedSuccessMsg: 'Contrato atualizado com sucesso!',
  contractUpdatedErrorMsg:
    'Houve um erro atualizando o contrato. Por favor, atualize e tente novamente.',
  committed_samples: 'Amostras comprometidas',
  contractType: 'tipo de contrato',
  incentive: 'Incentivo',
  discount: 'Desconto',
  committedSamples: 'Amostras comprometidas',
  signedAt: 'Assinado em',
  expiredOn: 'Expirou em',
  noContractsExistMsg: 'Não existem contratos para esta agência.',
  batchOrder: 'Pedido em lote',
  zymoSoil: 'Solo zimo',
  zymo: 'Zymo',
  zymoDilution: 'Diluição de Zymo',
  soilDilution: 'Diluição do solo',
  manageControls: 'Gerenciar controles',
  disable: 'Desativar',
  enterName: 'Insira o nome',
  enterDescription: 'Digite a descrição',
  enterCommaSeparatedValues: 'Digite valores separados por vírgula',
  dilution: 'Diluição',
  createControl: 'Criar controle',
  createIndexSet: 'Criar conjunto de índice',
  noIndexSetsFound: 'Nenhum conjunto de índice encontrado',
  backToList: 'De volta à lista',
  forwardPrimer: 'Primer para frente',
  forwardIndexSequence: 'Sequência de índice a diante',
  reversePrimer: 'Primer reverso',
  reverseIndexSequence: 'Sequência de índice reverso',
  createAttribute: 'Criar atributo',
  rndAttributes: 'Atributos de P&D',
  rndProtocols: 'Protocolos de P&D',
  allowedValues: 'Valores permitidos',
  createProtocol: 'Criar protocolo',
  dateSigned: 'Data assinada',
  expirationDate: 'Data de validade',
  current: 'Atual',
  plannedSamples: 'Amostras planejadas',
  samplesTaken: 'Amostras tomadas',
  completedSamples: 'Amostras concluídas',
  endContract: 'Contrato final',
  contractEndedSuccessMsg: 'O contrato terminou com sucesso!',
  doNotEnd: 'Não termine',
  areYouSure: 'Tem certeza',
  autoSubmit: 'Submissão automática',
  addBillingResponsibility: 'Adicione responsabilidade de cobrança',
  samplingHistory: 'Histórico de Amostragens',
  method: 'Método',
  manualRecord: 'Registro manual',
  manual: 'Manual',
  planName: 'Nome do plano',
  empty: 'Vazio',
  insufficient_data_performance: 'Dados insuficientes (desempenho)',
  firstSampleArrived: 'Amostra chegou pela primeira vez',
  wells: 'Poços',
  or: 'Ou',
  markedReadySuccessMsg: 'O plano foi marcado com sucesso pronto.',
  markedReadyFailureMsg:
    'Houve um erro marcando o plano pronto. Por favor, atualize e tente novamente.',
  samplerInstructions: 'Instruções de amostrador',
  noInstructionsAvailable: 'Sem instruções disponíveis.',
  days_marked_ready: 'Dias marcados prontos',
  commercialTerritory: 'Território comercial',
  clear: 'Claro',
  notTerritoryOwner:
    'Seu território designado não foi criado por você. Pressione Limpar para redefinir o mapa se quiser desenhar o seu.',
  territoryCreated: 'Território comercial salvo!',
  territoryUsers: 'Usuários de território',
  add: 'Adicionar',
  myAgencies: 'Minhas agências',
  territoryName: 'Nome do território',
  territory: 'Território',
  territorySavingErrMsg:
    'Houve um erro salvando o território. Por favor, atualize e tente novamente.',
  newTerritory: 'Novo territorio',
  users: 'Usuários',
  deleteTerritoryMsg: 'Tem certeza de que deseja excluir este território?',
  territoryDeletedMsg: 'Território excluído com sucesso!',
  owner: 'Proprietário',
  missingInfoMsg: 'Por favor, preencha todas as informações antes de salvar.',
  insufficientDataWarning:
    'Mais de 25% do seu campo tem dados insuficientes e você receberá um reembolso. As zonas marcadas como N/A não possuem dados suficientes.',
  switchUserToAgent: 'Mudar o usuário para agente',
  selectAgencyReassignMsg: 'Selecione a agência à qual eles devem pertencer ou criar um novo',
  createNewAgency: 'Crie nova agência',
  newAgencyName: 'Novo nome da agência',
  inPayment: 'Em pagamento',
  uncheckAll: 'Desmarcar tudo',
  bray: 'Zurro',
  olsen: 'Olsen',
  phosphorus: 'Fósforo',
  riskEstimate: 'Estimativa de risco',
  newRiskEstimate: 'Nova estimativa de risco',
  benefits: 'Benefícios',
  myFarmPlan: 'Meu plano de fazenda',
  myReport: 'Meu relatório',
  offer: 'Oferta',
  overview: 'Visão geral',
  patternReportAnalyzes:
    'O relatório Padrão 360 analisa seu solo para <b> prever o risco do próximo ano </b> de prejudicar pragas e doenças.',
  mapYourPressure:
    '<b> Mapeie sua pressão: </b> O padrão mede sua biologia do solo para criar <b> mapas de subfield de pragas e da doença. </b>',
  predictYourRisk:
    '<b>Preveja seus riscos: </b> Mapas de pragas e doenças ajudam a <b>prever os riscos de perda de produtividade</b> para a próxima safra.',
  createYourPlan:
    '<b> Crie seu plano: </b> O padrão 360 ajudará <b> Recomendar as entradas </b> que aumentarão o rendimento e otimizarão os gastos.',
  seeHowItWorks: 'Veja como funciona',
  viewSampleReport: 'Ver relatório de amostra',
  viewBenefits: 'Ver benefícios',
  createFarmPlan: 'Criar plano de fazenda',
  viewMyReport: 'Ver meu relatório',
  viewOffer: 'Ver oferta',
  theTypicalMidwestFarm:
    'A fazenda típica <b> cidwest </b> trabalhando com padrão pode aumentar os lucros por </b>',
  applyRecommendedInputs:
    "Aplique insumos recomendados com base no relatório do padrão 360 sobre campos 'em risco'",
  optimizeInputs: "Otimize as entradas com base no relatório do padrão 360 sobre campos 'em risco'",
  boostYield: 'Aumentar o rendimento',
  optimizeSpend: 'Otimizar gastos',
  startedWhichOperation: 'Para começar, com qual operação estamos trabalhando?',
  farmName: 'Nome da fazenda',
  primaryCounty: 'Condado primário',
  countyFootNote: 'Usado para estimar os riscos para sua operação',
  operatorName: 'Nome do operador',
  optional: 'opcional',
  operatorEmail: 'Email do operador',
  estimateYourRisk: 'Para estimar seu risco, conte -nos sobre seu plano de colheita:',
  cornPlan: 'Plano de milho',
  soybeanPlan: 'Plano de soja',
  traits: 'Características',
  seedTreatment: 'Tratamento de sementes',
  inFurrow: 'Em sulco',
  seedRisks: 'Riscos de sementes',
  rwStack: 'RW Stack',
  rwSingle: 'Rw único',
  aboveGroundOnly: 'Apenas o solo',
  fungicide: 'Fungicida',
  insecticide: 'Inseticida',
  fungicideInsecticide: 'Fungicida e inseticida',
  northCornLeafBlight: 'Praga de folha de milho norte',
  grayLeafSpot: 'Ponto de folha cinza',
  gossWilt: 'Wilt de Goss',
  tarSpot: 'Alcatrão',
  sds: 'SDS',
  nematicide: 'Nematicida',
  sdsFungicide: 'SDS + fungicida',
  sdsNematicide: 'SDS + nematicida',
  fungicideNematicide: 'Fungicida + nematicida',
  sdsFungicideNematicide: 'SDS + fungicida + nematicida',
  soybeansPlan: 'Plano de soja',
  yield: 'Colheita',
  price: 'Preço',
  operationName: 'Nome da Operação',
  searchOperation: 'Operação de pesquisa',
  searchCounty: 'Search County',
  searchCountyError: 'Ocorreu um erro ao procurar o município especificado. Por favor atualize.',
  totalReturn: 'Retorno total',
  estimateBasedOnFarmPlan:
    'Esta é uma estimativa baseada no seu plano agrícola e no risco previsto da Pattern AG para 2023 em seu município.',
  patternAgPersonalizedReport:
    'Seu relatório Padrão 360 fornecerá análises e recomendações personalizadas para sua fazenda.',
  estAcresAtRisk: 'Husa. HA em risco',
  protection: 'Proteção',
  estYieldAtRisk: 'Husa. Rendimento em risco',
  highRiskAreas: 'Áreas de alto risco',
  lowRiskAreas: 'Áreas de baixo risco',
  estYieldImpact: 'Husa. Rendimento de impacto',
  inputSavings: 'Economia de entrada',
  additionalInputSpend: 'Gasto de entrada adicional',
  estReturn: 'Husa. Retornar',
  estBenefit: 'Husa. Beneficiar',
  cost: 'Custo',
  return: 'Retornar',
  lockInOfferToday: 'Trava hoje',
  noRiskCancel: 'Sem risco, cancele a qualquer momento antes de amostragem',
  estimatedReturnAcross: 'Estimated return across your operation',
  lockInAcresNow: 'Lock in acres now to ensure timely sampling',
  createOperation: 'Criar operação',
  createOperationErrorMsg:
    'Houve um erro criando a operação. Por favor, atualize e tente novamente.',
  createOperationSuccessMsg: 'Operação criada com sucesso!',
  canUpdateLater: 'Você pode atualizar isso mais tarde',
  missingOperationIdMsg: 'Crie ou escolha uma operação existente.',
  missingCountyMsg: 'Selecione um município.',
  missingCropDetailsMsg: 'Por favor, preencha todas as informações da colheita.',
  cropProtectionCosts: 'Custos de proteção de culturas',
  revenue: 'Receita',
  invalidCornYieldMsg: 'Certifique -se de que seu rendimento de milho esteja entre 100 e 600.',
  invalidSoybeanYieldMsg: 'Verifique se o seu rendimento de soja está entre 20 e 100.',
  invalidCornPriceMsg: 'Certifique -se de que seu preço do milho esteja entre US $ 2 e US $ 10.',
  invalidSoybeanPriceMsg: 'Verifique se o preço da sua soja está entre US $ 5 e US $ 20.',
  postOperationCropInfoErrorMsg:
    'Houve um erro atualizando as informações da colheita. Por favor, atualize e tente novamente.',
  highRiskAcres: 'Acres de alto risco',
  lowRiskAcres: 'Acres de baixo risco',
  operationSummary: 'Resumo da operação',
  language: 'Linguagem',
  userLocaleUpdated: 'Local do usuário atualizado!',
  userRoleUpdated: 'Função do usuário atualizada!',
  report: 'Relatório',
  county: 'Condado',
  manageAgents: 'Gerenciar agentes',
  manageCommercialUsers: 'Gerenciar usuários comerciais',
  lockInOfferErrorMsg: 'Houve um erro de travamento em sua oferta. Por favor, tente novamente.',
  lockedInAcres: 'Trancado em acres',
  acreCommit: 'COMPRIMENTO ACRE',
  createEstimate: 'Criar estimativa',
  waiting: 'Esperando',
  foliarFungicide: 'Fungicida foliar',
  searchAccountOrViewAll: 'Procure uma conta ou veja tudo',
  locked: 'Trancado',
  expired: 'Expirado',
  nitrate_panel: 'Painel de nitrato',
  pressure_panel_only: 'Painel de pressão',
  complete_bio: 'Biografia completa',
  pattern_360: 'Padrão 360',
  nitrateAnalysis: 'Análise de nitrato',
  completeBioMsg:
    'Além do painel de pressão, mapeie o risco de doenças do solo e a oportunidade de biofertilidade, para otimizar os gastos com insumos',
  pattern360Msg:
    'Combine a biografia completa com nossa análise abrangente de macro e micronutrientes para identificar deficiências de nutrientes e equilibrar seu solo (não disponível para solo regulado neste momento)',
  nitrateAnalysisMsg:
    'Optional: Add nitrate testing to each zone where nutrient analysis will be completed',
  pressureAndPerformancePanelsZones: 'Zonas de painéis de pressão e desempenho',
  performAnalysisSingleDensity: 'Realizar todas as análises em uma única densidade',
  productOrdered: 'Produto ordenado',
  unableGenerateZoneGroupings: 'Não é possível gerar agrupamentos de zona. Por favor atualize.',
  printQRCode: 'Imprimir código QR',
  printSplitDensityBarcode: 'Imprimir código de barras de densidade dividida',
  discount_pressure_panel: 'Stine - Painel de pressão',
  country: 'País',
  operationCountryMsg: 'Este é o país onde a operação está localizada.',
  shippingTo: 'Enviando para',
  labelCount: 'Contagem de etiquetas',
  shippingRegulatedSoil: 'Solo regulado por remessa',
  patternAgDSCANumber: 'Número DSCA AG Pattern',
  viewOrderSummary: 'Visualize o resumo do pedido',
  summaryOfSamplePlanMsg:
    'Este é um resumo do plano de amostra, conforme solicitado. A fatura final refletirá quaisquer ajustes em potencial.',
  toBeSampledBy: 'Para ser amostrado por',
  labelDownloads: 'Downloads de etiquetas',
  runPlanned: 'Executar planejado',
  resultsUploaded: 'Resultados enviados',
  full: 'Completo',
  estimatedCompletionDate: 'Data prevista da conclusão',
  rerun: 'Reencontrar',
  technical: 'Técnico',
  process: 'Processo',
  defaultShippingLocation: 'Local de envio padrão',
  errorDefaultShippingMsg:
    'Houve um erro definindo o local de envio padrão. Por favor, tente novamente.',
  plateHasBatchErrorMsg:
    'A placa já existe em outro lote. Adicionar a placa a este lote o removerá do lote anterior',
  endOfResults: 'Fim dos resultados',
  predicted_ready: 'Previsto pronto',
  partially_sampled_long_duration: 'Parcialmente amostrado (longa duração)',
  marked_not_ready: 'Marcado não pronto',
  reads: 'Lê',
  cornPathogens: 'Pragas e doenças do milho',
  cornAndSoybeanPathogens: 'Pragas e doenças do milho e da soja',
  soybeanPathogens: 'Pragas e doenças da soja',
  cornFoliar: 'Milho foliar',
  soybeanFoliar: 'Soja foliar',
  detected: 'Detectado',
  notDetected: 'Não detectado',
  interLabShipping: 'Envio entre laboratórios',
  labShipmentId: 'ID de remessa de laboratório',
  to: 'Para',
  regulatedMaterial: 'Material regulamentado',
  regulatedMaterialMsg:
    'Se estiver enviando material regulamentado, certifique-se de que APENAS materiais regulamentados estejam na remessa',
  deliveredLabels: 'Etiquetas entregues',
  nutrientSamples: 'Amostras de nutrientes',
  genomicSamples: 'Amostras Genômicas',
  genomicPlates: 'Placas Genômicas',
  upsLabels: 'Etiquetas UPS',
  noLabelsListMsg: 'Nenhuma etiqueta de envio encontrada',
  deliveryDate: 'Nenhum marcador encontrado',
  include: 'Incluir',
  noShipmentsFoundMsg: 'Nenhuma remessa encontrada',
  createLabShipment: 'Criar remessa de laboratório',
  addLabel: 'adicionar etiqueta de envio',
  shipmentCreatedMsg: 'Remessa criada com sucesso',
  shipmentUpdatedMsg: 'Remessa atualizada com sucesso',
  saveChanges: 'Salvar alterações',
  unknown: 'desconhecido',
  preTransit: 'Pré-trânsito',
  transit: 'Transito',
  delivered: 'Entregue',
  returned: 'Devolvido',
  failure: 'Falha',
  shipping: 'Envio',
  shippedLabels: 'Etiquetas enviadas',
  not_detected: 'Não detectado',
  on: 'Sobre',
  off: 'Desligado',
  sugarBeets: 'Beterraba',
  analysisOptionsMsg: 'Opções de análise atualizadas com sucesso!',
  sugarBeetPestsDiseases: 'Pragas e doenças da beterraba',
  'sugar-beets': 'Beterraba',
  analysisOptions: 'Opções de análise',
  dlBarcodeFile: 'Arquivo de código de barras DL',
  viewAdditionalResults: 'Ver resultados adicionais',
  searchCurrentBounds: 'Pesquisar limites atuais',
  showing: 'Mostrando',
  removeSearchBounds: 'Remover limites de pesquisa',
  cancelOrder: 'Cancelar pedido',
  supportUnderTwoAcres:
    'Nosso sistema não suporta campos com menos de 0,9 hectares. Por favor, redimensione.',
  myAlerts: 'Meus Alertas',
  csvColumnsRequiredMsg: 'O arquivo deve conter as seguintes colunas',
  printHeader: 'Seu solo está falando. É hora de ouvir.',
  season: 'Safra',
  productAdmin: 'Administrador de Produto',
  bulkAssign: 'Atribuição em massa',
  clickToAssignPlans: 'Clique Para Atribuir Planos',
  markNotArrived: 'Marca Não Chegou',
  mapbookConfiguration: 'Configuração do livro de mapas',
  english: 'Inglês',
  portuguese: 'Português',
  mapbookGenerationFailedMsg: 'Falha na geração do livro de mapas. Por favor, tente novamente.',
  overviewOnly: 'Visão geral apenas',
  snoozeAlerts: 'Suspender Alertas',
  dismissAlerts: 'Dispensar Alertas',
  selectAlertsToSnooze: 'Selecione Alertas para Adiar',
  selectAlertsToDismiss: 'Selecione Alertas para Dispensar',
  updatingAlertError: 'Ocorreu um erro ao atualizar o alerta',
  selfSampling: 'Autoamostragem',
  permitirMultiplePlans: 'Permitir vários planos abertos por campo',
  noBillingUserMsg: 'Adicione uma responsabilidade de cobrança antes de fazer pedidos',
  optOutText:
    'Você optou por não receber notificações da Pattern Ag por mensagem de texto. Aguarde 24 horas para que as alterações entrem em vigor.',
  plateArrived: 'Placa chegou',
  eggs: 'Ovos',
  'dna copies': 'Cópias de DNA',
  worms: 'Vermes',
  noResultsForCategory: 'Nenhum resultado para esta categoria.',
  not_analyzed: 'Não analisado',
  operationNoDiscountsMsg: 'Esta operação não possui programas de descontos.',
  editDiscountProgram: 'Editar programa de desconto',
  maxAcres: 'Acres máximos',
  regualted: 'Regulamentado',
  updatePrescriptionZoneErrorMsg:
    'Erro ao atualizar a zona de prescrição. Atualize e tente novamente.',
  updatePrescriptionZoneSuccessMsg: 'Zona de prescrição atualizada com sucesso!',
  john_deere: 'John Deere',
  field_view: 'Field View',
  cnhi: 'CNHI',
  sendTo: 'Enviar para',
  lime: 'Calcário',
  potassium: 'Potássio',
  isuPhosphorus: 'ISU Fósforo',
  isuLimeTo6: 'ISU Calcário para 6',
  isuLimeTo65: 'ISU Calcário para 6.5',
  isuLimeTo69: 'ISU Calcário para 6.9',
  isuPotassium: 'ISU Potássio',
  initiatedExportFailMsg: 'Falha ao iniciar a exportação. Atualize e tente novamente.',
  prescriptionDownloadFailMsg: 'Falha ao baixar a prescrição. Atualize e tente novamente.',
  prescriptionDownloadSuccessMsg: 'Receita baixada com sucesso!',
  exportInitiatedMsg: 'Sua exportação foi iniciada!',
  naptControls: 'Controles NAPT',
  controlCode: 'Código de Controle',
  createNaptControl: 'Criar Controle NAPT',
  assignUserToAgency: 'Atribuir Usuário à Agência',
  of: 'de',
  haveReadAndAgreeToPatternAg:
    'Ao clicar em “Concordo”, reconheço que li e compreendi o Contrato de Licença de Usuário Final e a Política de Privacidade da PatternAg, Inc., e aceito e concordo em ficar vinculado a todos os termos e condições do Usuário Final da PatternAg, Inc. Contrato de licença:',
  haveReadAndAgreeToPatternAgIncludingTerms:
    'Ao clicar em “Concordo”, reconheço que li e compreendi o Termos e Condiçõess e Contrato de Licença de Usuário Final e a Política de Privacidade da PatternAg, Inc., e aceito e concordo em ficar vinculado a todos os termos e condições do Usuário Final da PatternAg, Inc. Contrato de licença:',
  termsAndConditions: 'Termos e Condiçõess',
  endUserLicenseAgreement: 'Contrato de Licença de Usuário Final',
  privacyPolicy: 'Política de Privacidade',
  createPassword: 'Concordar e Criar Senha',
  noLogMeOut: 'Não, desconecte-me',
  userAgreements: 'Acordos de Usuário',
  incomplete: 'Incompleto',
  success: 'Sucesso',
  uploadLogo: 'Carregar Logotipo',
  triStatePhosphorus: 'Tri State Fósforo',
  triStatePotassium: 'Tri State Potássio',
  triStateLimeTo6: 'Tri State Calcário para 6.0',
  triStateLimeTo65: 'Tri State Cal para 6.5',
  triStateLimeTo68: 'Tri State Calcário para 6.8',
  triStateOhLimeTo6: 'Tri State (OH) Calcário para 6.0',
  triStateOhLimeTo65: 'Tri State (OH) Calcário para 6.5',
  triStateOhLimeTo68: 'Tri State (OH) Calcário para 6.8',
  pressure_nutrient_panels: 'Painel de Pressão + Nutrientes',
  pressureNutrientPanelsDescription:
    'Nosso Painel de Pressão mais nossa análise abrangente de macro e micronutrientes',
  sdsuPhosphorusOlsen: 'SDSU/MN Fósforo (OLSEN)',
  sdsuPhosphorusBray: 'SDSU/MN Fósforo (Bray)',
  sdsuPotassium: 'SDSU/MN Potássio',
  sdsuLime: 'SDSU Calcário',
  updateAddressFailMsg: 'Falha ao atualizar o endereço. Atualize e tente novamente.',
  updateUserInfoFailMsg:
    'Falha ao atualizar as informações de usuário. Atualize e tente novamente.',
  invalidCellPhoneMsg: 'Por favor, insira um número de celular válido.',
  saveContactInfo: 'Salvar informações de contato',
  billingAddressMissingMsg: 'Por favor, adicione um endereço de cobrança.',
  lossOnIgnition: 'Perda de Ignição',
  fileSizeTooLarge: 'O tamanho do arquivo deve ser inferior a 2 MB.',
  incorrectImageDimensions: 'As dimensões da imagem devem ser pelo menos 1x1.',
  bulkDelete: 'Excluir em Massa',
  bulkExport: 'Exportar em Massa',
  options: 'Opções',
  selectAll: 'Selecionar tudo',
  removeSelected: 'Remover Selecionado',
  exportSelected: 'Exportar Selecionado',
  bulkDeleteRxMsg:
    'Isso removerá todas as prescrições selecionadas. Pressione confirmar para continuar.',
  bulkExportRxMsg:
    'Isto exportará todas as prescrições selecionadas. Pressione confirmar para continuar.',
  whereSendInvoiceMsg: 'Informe-nos para onde devemos enviar as faturas pendentes desta conta',
  errorSettingBillingResMsg:
    'Erro ao definir a responsabilidade de cobrança. Por favor, tente novamente.',
  performance_panel_list_low_msg: 'O preço da lista Biografia Completa é muito baixo',
  performance_panel_list_high_msg: 'O preço da lista Biografia Completa é muito alto',
  nutrient_panel_list_low_msg: 'O preço de tabela do Padrão 360 é muito baixo.',
  nutrient_panel_list_high_msg: 'O preço de tabela do Padrão 360 é muito alto.',
  pressure_panel_list_low_msg: 'O preço de lista del Panel de Presión é muito baixo.',
  pressure_panel_list_high_msg: 'O preço de lista del Panel de Presión é muito alto.',
  checkBoxToContinue: 'caixa de seleção para continuar',
  calciumCarbonateEquivalent: 'Equivalente de Carbonato de Cálcio',
  relativeNeutralization: 'Neutralização Relativa',
  neutralization: 'Neutralização',
  selectClus: 'Selecione CLUs',
  addClus: 'Adicionar CLUs',
  reloadClus: 'Recarregar CLUs',
  failedToLoadClusMsg: 'Falha ao carregar CLUs',
  cluInstructions1:
    'Selecione CLU(s) para começar a mapear um limite. Clicar em "Recarregar CLUs" atualizará os limites após mover o mapa.',
  cluInstructions2: 'Clique em "Adicionar CLUs" para editar os limites selecionados.',
  cluInstructions3: 'Amplie para carregar CLUs',
  addFieldInstructions: 'Forneça um nome de campo e clique em "salvar" para continuar.',
  addLocation: 'Adicionar localização',
  manageAgencyLocations: 'Gerenciar locais da agência',
  addAgency: 'Adicionar agência',
  locations: 'Localizações',
  manageLocations: 'Gerenciar locais',
  createOrAddAgencyUnder: 'Crie ou adicione uma agência abaixo',
  isExistingAgency: 'É uma agência existente?',
  noAgenciesExist: 'Nenhuma agência existe',
  brazilSaturationMethod: 'Brasil - Método de Saturação',
  targetBaseSaturation: 'Saturação Base Alvo',
  manageParentAgency: 'Gerenciar agência principal',
  baseSaturation: 'Saturação Base',
  agencies: 'Agências',
  deleteAgencyOperationConnection: 'Excluir conexão agência para',
  removeAgencyConnectionMsg:
    'Isso removerá o acesso da agência a esta operação. No entanto, quaisquer agentes diretamente ligados a esta operação continuarão a ter acesso.',
  unableToConnectAgency: 'Não é possível conectar a agência. Por favor, tente novamente.',
  unableToDisconnectAgency: 'Não é possível desconectar a agência. Por favor, tente novamente.',
  agencyConnectionRemovedMsg: 'Conexão de agência removida com sucesso!',
  agencyConnectionAdded: 'Conexão de agência adicionada com sucesso!',
  targetPhosphorus: 'Saturação Fósforo',
  brazilPhosphorus: 'Brasil - Fósforo',
  seller: 'Vendedor',
  partner: 'Parceiro',
  pro: 'Pro',
  tooLowMsg: 'Muito baixo',
  tooHighMsg: 'Muito alto',
  selectAgencyMsg: 'Selecione Uma Agência Para Visualizar',
  selectAgencyToBeginMsg: 'Para Começar, Selecione Uma Agência No Menu Suspenso Acima',
  allRiskLevels: 'Todos Os Níveis De Risco',
  analyticRiskMap: 'Mapa De Risco Analítico',
  percentOfFieldsAtModerateOrHighRisk: 'Porcentagem De Campos Com Risco Moderado Ou Alto',
  show: 'Mostre',
  filteredResults: 'Resultados Filtrados',
  totalAcres: 'Total de Hectares',
  fieldRisk: 'Risco de Campo',
  highRiskFields: 'Campos de Alto Risco',
  moderateRiskFields: 'Campos de Risco Moderado',
  lowRiskFields: 'Campos de Baixo Risco',
  totalPlannedSamples: 'Total de amostras planejadas',
  riskMap: 'Mapa De Risco',
  totalSamplesTaken: 'Total de amostras tomadas',
  totalCompletedSamples: 'Total de amostras concluídas',
  agencyContractWarningMsg:
    'O contrato da agência de cobrança expirou: os pedidos serão faturados no MSRP',
  parent_agency: 'Agência principal',
  manage: 'Gerenciar',
  addAgents: 'Adicionar agentes',
  agencyUpdatedSuccessMsg: 'Agência atualizada com sucesso!',
  parentAgency: 'Agência principal',
  agencyDefaults: 'Padrões da agência',
  displayedRecommendationSet: 'Conjunto de recomendações exibido',
  value: 'Valor',
  new: 'Novo',
  newOrParentContract: 'Novo ou contrato principal?',
  next: 'Próximo',
  enterEmailToSignIn: 'Digite seu e-mail para entrar',
  noUserFoundMsg: 'Nenhum usuário encontrado com esse e-mail.',
  howWouldyouLikeToSignIn: 'Como você gostaria de entrar?',
  signInWithPassword: 'Entrar com senha',
  sendCodeTo: 'Enviar código para',
  emailCodeTo: 'Código de e-mail para',
  goBack: 'Voltar',
  codeSentSuccessMsg: 'Código enviado com sucesso!',
  codeSentFailMsg: 'Houve um erro enviando o código. Por favor, tente novamente.',
  welcomeBack: 'Bem-vindo de volta',
  enterCodeSentTo: 'Digite o código de 6 dígitos enviado para',
  resendCode: 'Reenviar código',
  signInDifferentWay: 'Entrar de maneira diferente',
  enterPasswordFor: 'Digite sua senha para',
  splitDensityGroup: 'Grupo de Densidade Dividida',
  biologicalSubsample: 'Subamostra Biológica',
  codeIncorrectMsg: 'Este código está incorreto ou expirou. Por favor, reenvie e tente novamente.',
  mergeFields: 'Mesclar campos',
  splitFields: 'Dividir campos',
  selectFieldsToMerge:
    'Selecione limites de campo para mesclar que não tiveram planos de amostra anteriores.',
  confirmMerge: 'Confirmar mesclagem',
  cancelMerge: 'Cancelar mesclagem',
  fieldsMergedSuccessMsg: 'Campos mesclados com sucesso!',
  manageFields: 'Gerenciar campos',
  confirmSplit: 'Confirmar divisão',
  cancelSplit: 'Cancelar divisão',
  newFieldName: 'Novo nome do campo',
  fieldSplitSuccessMsg: 'Campo dividido com sucesso!',
  missingSelectionOverlapMsg: 'Falta sobreposição entre campo e seleção.',
  selectFieldsToSplit:
    'Selecione limites de campo para dividir que não tiveram planos de amostra anteriores.',
  selectDiscountProgram: 'Selecione um programa de desconto',
  programToEnrollMsg: 'Qual programa você deseja se inscrever?',
  maxAcresForEnrollment: 'Acres máximos para inscrição',
  discount_sampling_fee: 'Taxa de amostragem com desconto',
  discount_panels: 'Painéis com desconto',
  'pagi-2024': 'PAGI',
  max: 'Máximo',
  moisture: 'Umidade',
  addEditBoundaries: 'Adicionar / Editar Limites',
  lane: 'Pista',
  orderHistory: 'Histórico de Pedidos',
  panelsOrdered: 'Painéis Encomendados',
  pendingDelivery: 'Entrega pendente',
  planMarkedDeliveredSuccess: 'Planos marcados como entregues com sucesso!',
  noOrderHistoryMsg: 'Nenhum histórico de pedidos encontrado para o ano atual',
  removeAgencyLocation: 'Remover Agência/Localização?',
  removeAgencyLocationWarning: 'Isso removerá a conexão Agência/Localização. Tem certeza?',
  printOptions: 'Opções de impressão',
  mapbookDownloads: 'Downloads de mapas',
  downloadOptions: 'Baixar downloads',
  noResultsExistForField: 'Não existem resultados para esta campo.',
  sikora: 'Sikora',
  deleteTerritory: 'Excluir Território',
  addTargetYieldMsg: 'Adicione um produtividade alvo para continuar.',
  updateFieldCropErrorMsg: 'Erro ao atualizar o plano de cultivo. Atualize e tente novamente.',
  updateFieldCropSuccessMsg: 'Plano de cultivo atualizado com sucesso!',
  noDataForFieldAnalytic: 'Não há dados para este campo/análise.',
  sugar_beets: 'Beterraba',
  planning: 'Planejamento',
  billingAgency: 'Agência de Cobrança',
  editBatch: 'Editar Lote',
  addBatch: 'Adicione Lote',
  createSampleSheet: 'Criar Folha de Amostra',
  seed: 'Semente',
  treatment: 'Tratamento',
  openRisks: 'Riscos abertos',
  in_furrow: 'Em sulco',
  optimizePlan: 'Otimizar planos',
  planSummary: 'Resumo do plano',
  fertility: 'Fertilidade',
  unit: 'Unidade',
  units: 'Unidades',
  avgRate: 'Taxa média',
  seedResistance: 'Resistência de sementes',
  select: 'Selecione',
  good: 'Bom',
  exc: 'Exc',
  resistanceOptions: 'Opções de resistência',
  addProduct: 'Adicionar produto',
  optimizedPlansSuccessMsg: 'Planos otimizados com sucesso!',
  optimizedPlansErrorMsg: 'Erro ao otimizar planos. Atualize e tente novamente.',
  optimizingPlans: 'Otimizando planos',
  arrivedLab: 'Laboratório de Chegada',
  blendedLab: 'Laboratório de Liquidificador',
  dealerPortal: 'Portal do Revendedor',
  deletePDFMsg: 'Tem certeza de que deseja excluir este PDF?',
  deletePDFErrorMsg: 'Erro ao excluir PDF. Atualize e tente novamente.',
  deletePDFSuccessMsg: 'PDF excluído com sucesso!',
  PDFCropSelectMsg:
    'Se nenhuma cultura for selecionada, apenas a próxima colheita será mostrada para cada campo.',
  PDFFieldsDisabledMsg: 'Os campos serão desabilitados caso os resultados ainda não existam.',
  cotton: 'Algodão',
  getStartedCreateAccount: 'Para começar, crie sua conta agrícola',
  inviteNewUser: 'Convidar novo usuário',
  inviteUserMsg:
    'O usuário que você está convidando ainda não possui uma conta no Pattern. Forneça mais algumas informações sobre eles para completar o convite',
  fieldsWithErrors: 'Os campos com * são obrigatórios.',
  billingResponsibility: 'Responsabilidade de cobrança',
  noUserWithBillingResMsg:
    'Não há nenhum usuário com responsabilidade de cobrança para esta conta. Você poderá criar planos de amostra para esta conta, mas não poderá coletar amostras em campo até que um usuário seja adicionado aqui.',
  addingBillingResponsibility: 'Adicionando responsabilidade de cobrança',
  omAnalysis: 'Análise de OM',
  pipeline: 'Gasoduto',
  extractionMethod: 'Método de Extração',
  allExtractions: 'Todas as Extrações',
  allPipelines: 'Todos os Pipelines',
  modifiedMehlich: 'Mehlich Modificado',
  mehlich: 'Mehlich',
  addSeedChart:
    'Selecione um híbrido ou variedade que forneça um nível adequado de proteção. Adicionar híbridos ao seu Catálogo de Produtos exibirá seus níveis de proteção aqui.',
  addProductChart:
    'Selecione um produto que forneça um nível adequado de proteção. Adicionar produtos ao seu Catálogo de Produtos exibirá seus níveis de proteção aqui.',
  analyticAttributes: 'Atributos Analíticos',
  analyticTaxonomies: 'Taxonomias Analíticas',
  referencesAndResources: 'Referências e Recursos',
  analyticImagery: 'Imagens Analíticas',
  showInUI: 'Mostrar na IU',
  analyticName: 'Nome Analítico',
  category: 'Categoria',
  environmentalConsiderations: 'Considerações Ambientais',
  backToAnalytics: 'Voltar para Analíticos',
  addReferencesOrResources: 'Adicionar Referências ou Recursos',
  referenceLink: 'Link de referência',
  uploadAnalyticImagery: 'Carregar Imagens Analíticas',
  imageLabel: 'Rótulo da imagem',
  addNewImage: 'Adicionar nova imagem',
  manageAnalytics: 'Gerenciar Analíticos',
  newAnalytic: 'Novo Analítico',
  crops: 'Culturas',
  is_overview_nd: 'É Visão Geral ND',
  is_field_nd: 'É Campo ND',
  is_sample_nd: 'É Amostra ND',
  is_field_result_binary: 'É Resultado de Campo Binário',
  countries: 'Países',
  displayOrder: 'Ordem de exibição',
  displayCategory: 'Categoria de exibição',
  addAnalytic: 'Adicionar Analítico',
  removeAnalyticFromView: 'Remover Analítico da Visualização?',
  analyticOrderUpdated: 'Ordem analítica atualizada com sucesso!',
  seedMissing:
    'As opções híbridas não foram adicionadas ao catálogo de produtos. Clique para revisar',
  treatmentMissing:
    'As opções de tratamento de sementes não foram adicionadas ao catálogo de produtos. Clique para revisar',
  in_furrowMissing:
    'As opções de proteção no sulco não foram adicionadas ao catálogo de produtos. Clique para revisar',
  foliarMissing:
    'As opções de proteção foliar não foram adicionadas ao catálogo de produtos. Clique para revisar',
  fieldResultsOverview: 'Resultados de campo - Visão geral',
  copyFromCorn: 'Copiar de milho',
  doNotBill: 'Não faturar',
  notSentToNetsuiteMsg: 'Os planos de amostragem de operação não serão cobrados no NetSuite.',
  analysisAndSampling: 'Análise e Amostragem',
  brazilPotassium: 'Brasil - Potássio',
  targetPotassium: 'Saturação Potássio',
  pro10Res: 'Pro (Resolução de 0x10 metros)',
  zymoSilicaDescription: 'lise zymo e isolamento de filtro de sílica',
  omniRevvityPbWashDescription: 'omni lise e magbead com lavagem PB',
  zymoRevvityDescription: 'zymo lise e magbead com método TBD para quelar íons metálicos',
  omniRevvityDescription: 'omni lise e magbead com método TBD para quelar íons metálicos',
  lockPoints: 'Pontos de bloqueio',
  soilProcessing: 'Processamento de Solo',
  wet: 'Molhada',
  dry: 'Seca',
  selectZones: 'Selecione Zonas',
  generatePoints: 'Gerar Pontos',
  setupZonesInstructionsMsg:
    'Configure zonas para análise biológica e gere pontos para análise de nutrientes e digitalização profissional',
  nextStep: 'Próximo passo',
  chooseAnalysisMsg: 'Escolha uma análise para continuar',
  pro_sensor_scan: 'Pro Sensor Scan',
  pro_map_generation: 'Geração de Mapa Pro',
  canScanProPlans: 'Digitalização 360 Pro Ativada',
  userScanningUpdated: 'Capacidade de digitalização do usuário atualizada!',
  soloCollection: 'Coleta de Solo',
  soloScan: 'Varredura do Solo',
  history: 'Histórico',
  scanHistory: 'Histórico de Verificação',
  dataReview: 'Rrevisão de Dados',
  soilCollection: 'Coleta de Solo',
  soilScan: 'Varredura do Solo',
  nutrientPoints: 'Pontos de Nutrientes',
  resetPoints: 'Redefinir Pontos',
  minimalNumberPointsMsg: 'Adicione mais pontos para enviar o plano.',
  editPoints: 'Editar Pontos',
  selfScanning: 'Auto Digitalização',
  scannedBy: 'Digitalizado por',
  toBeScannedBy: 'Para ser digitalizado por',
  waitingToScan: 'Aguardando digitalização',
  readyToScan: 'Pronto para digitalizar',
  planNames: 'Nome(s) do Plano',
  viewScanCoverage: 'Ver Cobertura da Digitalização',
  totalScanCoverage: 'Cobertura Total de Digitalização',
  tillMapper: 'TillMapper',
  tillage_map_generation: 'Geração de Mapa de Preparo',
  commission_tillage_map_generation: 'Comissão de Geração de Mapa de Preparo',
  tillage_measurement: 'Medição de Preparo',
  collectionsToReview: 'coleções para revisar',
  collectionDate: 'Date de Collecttion',
  collectionName: 'Nom de la collection',
  scanningTime: 'Durée de Digitalização',
  confirmFieldAssignment: 'Confirmar Atribuição de Campo',
  noDataToReview: 'Nenhum Dado Para Revisar',
  fieldCoverage: 'Cobertura de Campo',
  minutesAbbreviated: 'min',
  confirmCollectionMsg1:
    'Esta etapa vinculará os planos de amostra selecionados aos dados de varredura listados abaixo deles.',
  confirmCollectionMsg2:
    'Por favor, certifique-se de que todos os dados de digitalização necessários foram carregados para cada plano de amostra antes de confirmar e aguarde para confirmar um plano de amostra se ele não tiver sido completamente digitalizado.',
  tillMapperPoints: 'Pontos de TillMapper',
  deletingCollection: 'Excluindo Coleção...',
  collectionDeleted: 'Coleção excluída.',
  deleteCollection: 'Excluir coleção',
  deleteCollectionConfirm: 'Tem certeza? Esta coleção não pode ser recuperada depois de excluída.',
  tillMapperDensityMsg: 'A densidade do TillMapper deve ser maior que 8 e menor que 10,5.',
  proResults: 'Resultados Pro',
  pointResults: 'Resultados de Pontos',
  silver: 'Prata',
  gold: 'Ouro',
  platinum: 'Platina',
  region: 'Região',
  sampleOnly: 'Apenas Amostra',
  samplePlusScan: 'Amostra + Digitalização',
  assignSamplersSuccessMsg: 'Amostradores/Digitalizador atribuídos com sucesso!',
  wheat: 'Trigo',
  secondaryAnalytic: 'Analítico Secundário',
  secondaryAnalyticMsg: 'Esta análise será exibida se a análise atual não tiver resultados.',
  analysis590required:
    'Aviso: 590 Análise Necessária - Processo por meio de secagem/moagem para apoiar a análise downstream',
  analysis590Title: '590 Análise de Gestão de Estrume',
  analysis590Description:
    'Isto incluirá análises adicionais em apoio a 590 planos de Gestão de Estrume',
  searchAnalytic: 'Pesquisar Analítico',
  chemistryBackup: 'Backup de Química',
  backup: 'Backup',
  phosphateAnalysis: 'Análise de fosfato',
  phosphate: 'Fosfato',
  manageCatalogs: 'Gerenciar Catálogos',
  catalogDetails: 'Detalhes do catálogo',
  active: 'Ativo',
  addProducts: 'Adicionar Produtos',
  addSeeds: 'Adicionar sementes',
  addProductsAbove: 'Adicionar produtos acima',
  hybrid: 'Híbrido',
  seedCatalog: 'Catálogo de sementes',
  viewEdit: 'Ver/Editar',
  seedTreatments: 'Tratamentos de Sementes',
  inFurrowProducts: 'Produtos no Sulco',
  foliarProducts: 'Produtos foliares',
  manufacturer: 'Fabricante',
  noProductsToDisplay: 'Nenhum produto para exibir',
  noSeedToDisplay: 'Nenhuma semente para exibir',
  clickToExpand: 'Clique para expandir',
};

export default PORTUGUESE_STRINGS;
