import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, FlexProps, Table, Text, Title } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { FiDelete } from 'react-icons/fi';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { CollapsibleRow } from 'common';
import {
  AgronomicProductType,
  CombinedAnalyticType,
  PestGroupPayloadType,
} from 'store/cropPlans/types';
import { AnalyticType } from 'store/analytics/types';
import { CatalogType } from 'store/catalogs/types';
import { requestCatalogRemoveProduct } from 'store/cropPlans/requests';
import { receiveSingleCatalog } from 'store/catalogs/actions';

import InputCoverageTable from './InputCoverageTable';
import InputModal from './InputModal';

type ProductRowsPropsType = {
  products: AgronomicProductType[];
  analytics: AnalyticType[];
  pestGroups: PestGroupPayloadType;
  catalog: Partial<CatalogType>;
  setToastMessage: (message: string, type?: string, time?: number) => void;
  category: string;
  label?: string;
  mt?: FlexProps['mt'];
};

const ProductRows = ({
  products,
  analytics,
  catalog,
  pestGroups,
  setToastMessage,
  category,
  label,
  mt,
}: ProductRowsPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalInput, setModalInput] = useState<AgronomicProductType | null>();

  const getModalInputRatings = (product: AgronomicProductType) => {
    return analytics.reduce((all: { [key: number]: CombinedAnalyticType }, analytic) => {
      const catalogInput = catalog.products?.[product.id];
      if (!catalogInput) {
        return all;
      }

      const analyticInCoverage = catalogInput.ratings.find(
        (rating) => rating.analytic_id === analytic.id,
      );
      if (analyticInCoverage) {
        return { ...all, [analytic.id]: { ...analytic, ...analyticInCoverage } };
      }
      return { ...all, [analytic.id]: { ...analytic, coverage_rating: 0 } };
    }, {});
  };

  const rowButtonWrapper = (e: React.MouseEvent, buttonFunction: () => void) => {
    e.stopPropagation();
    buttonFunction();
  };

  const handleRemoveProduct = async (product: AgronomicProductType) => {
    try {
      const response = await requestCatalogRemoveProduct([product.id], catalog?.id);
      dispatch(receiveSingleCatalog(response));
      setToastMessage(`${product.name} successfully removed.`);
      navigate(`/catalog/${response.id}`);
    } catch (error) {
      setToastMessage('Failed to remove product. Try again later.', 'error');
    }
  };

  const productRows = () => (
    <>
      <Table.Thead>
        {label && (
          <Table.Tr>
            <Table.Th colSpan={6}>
              <Flex w="100%" justify="left" mt={mt}>
                <Title order={4}>{getString(label, language)}</Title>
              </Flex>
            </Table.Th>
          </Table.Tr>
        )}
        <Table.Tr>
          <Table.Th w={'10%'} />
          <Table.Th w={'20%'}>{getString('name', language)}</Table.Th>
          <Table.Th w={'20%'}>{getString('manufacturer', language)}</Table.Th>
          <Table.Th w={'20%'}>{getString('crop', language)}</Table.Th>
          <Table.Th w={'20%'}>{getString('protection', language)}</Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {products?.length ? (
          products.map((product) => (
            <CollapsibleRow
              key={product.id}
              collapseComponent={
                <InputCoverageTable
                  analytics={analytics}
                  category={category}
                  pestGroups={pestGroups}
                  product={product}
                  catalog={catalog}
                  pl="md"
                  pt="md"
                />
              }
            >
              <Table.Td w={'10%'}>
                <FiDelete
                  color="red"
                  cursor="pointer"
                  onClick={(e) => rowButtonWrapper(e, () => handleRemoveProduct(product))}
                  size={28}
                />
              </Table.Td>
              <Table.Td w={'20%'}>{product.name}</Table.Td>
              <Table.Td w={'20%'}>{product.registrant}</Table.Td>
              <Table.Td w={'20%'}>{product.crop}</Table.Td>
              <Table.Td w={'20%'} fw="bold">
                <Button variant="subtle" ml="-1.1rem">
                  {getString('clickToExpand', language)}
                </Button>
              </Table.Td>
              <Table.Td>
                <Button onClick={(e) => rowButtonWrapper(e, () => setModalInput(product))}>
                  {getString('viewEdit', language)}
                </Button>
              </Table.Td>
            </CollapsibleRow>
          ))
        ) : (
          <Table.Tr>
            <Table.Td colSpan={6}>
              <Text ta="center">{getString('noProductsToDisplay', language)}</Text>
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </>
  );

  return (
    <>
      {productRows()}
      {modalInput && (
        <InputModal
          closeModal={() => setModalInput(null)}
          defaultProductRatings={getModalInputRatings(modalInput)}
          isTemporary={false}
          modalOpened={Boolean(modalInput)}
          catalog={catalog}
          selectedProduct={modalInput}
          selectedSeed={null}
        />
      )}
    </>
  );
};

export default ProductRows;
