import React, { useState } from 'react';
import {
  Button,
  ComboboxItem,
  Group,
  Select,
  Space,
  Stack,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';
import { FiSearch } from 'react-icons/fi';
import { Header } from 'common/Components/Mantine/Header';
import { Typeahead } from 'common/Components/Mantine/Typeahead';
import { PRODUCT_TYPE_SEED, REC_SET_PRODUCT_TYPES } from 'constants/cropPlan';
import { CORN, SOYBEANS } from 'constants/variables';
import { AgronomicProductType, SeedType } from 'store/cropPlans/types';
import { requestGetProductSearch, requestGetSeedSearch } from 'store/cropPlans/requests';
import { DEBOUNCE } from 'util/request';
import { CatalogType } from 'store/catalogs/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_STANDARD } from 'constants/mantine';
import { getCropOptions, sortInputsByCoverage } from 'util/cropPlans';

import InputModal from './Common/InputModal';

interface ProductSearchProps {
  closeModal: VoidFunction;
  isSaving: boolean;
  modalOpened: boolean;
  onError: (msg: string) => void;
  openModal: VoidFunction;
  toggleIsSaving: (saving: boolean) => void;
  catalog: Partial<CatalogType>;
}

const ProductSearch = ({
  closeModal,
  isSaving,
  modalOpened,
  onError,
  openModal,
  toggleIsSaving,
  catalog,
}: ProductSearchProps) => {
  const language = useBroswerLanguage();
  const [productType, setProductType] = useState<string | null>(PRODUCT_TYPE_SEED);
  const [crop, setCrop] = useState<string>(CORN);
  const [productName, setProductName] = useState('');
  const [trait, setTrait] = useState('');

  const [products, setProducts] = useState<AgronomicProductType[]>([]);
  const [seeds, setSeeds] = useState<SeedType[]>([]);

  const [selectedSeed, setSelectedSeed] = useState<SeedType | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<AgronomicProductType | null>(null);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const debounceFetchProducts = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: AgronomicProductType[] = await requestGetProductSearch(name, crop);
        setProducts(response);
      } catch (error) {
        onError('Failed to fetch agronomic products.');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );
  const debounceFetchSeeds = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: SeedType[] = await requestGetSeedSearch(name, crop);
        setSeeds(response);
      } catch (error) {
        onError('Failed to fetch seeds.');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );

  const handleDropdownSelection = (product: { label: string }) => {
    if (productType === PRODUCT_TYPE_SEED) {
      const seed = seeds.find((s) => s.hybrid === product.label);
      if (seed) {
        setProductName(product.label);
        setTrait(seed.traits.join(','));
        setSelectedSeed(seed);
        setSelectedProduct(null);
      }
    } else {
      const foundProduct = products.find((p) => p.name === product.label);
      if (foundProduct) {
        setProductName(product.label);
        setSelectedSeed(null);
        setSelectedProduct(foundProduct);
      }
    }
  };

  const handleProductNameChange = (name: string) => {
    setProductName(name);
    if (name && !isSaving) {
      if (productType === PRODUCT_TYPE_SEED) {
        debounceFetchSeeds(name);
      } else {
        debounceFetchProducts(name);
      }
    }
  };

  const options = (() => {
    const data =
      productType === PRODUCT_TYPE_SEED
        ? seeds.map((seed) => ({
            id: seed.id,
            label: seed.hybrid,
            fw: seed.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
            coverage_ratings: seed.coverage_ratings,
          }))
        : products.map((product) => ({
            id: product.id,
            label: product.name,
            fw: product.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
            coverage_ratings: product.coverage_ratings,
          }));
    const sorted = sortInputsByCoverage(data);
    return sorted.map((p) => ({
      id: p.id,
      label: p.label,
      value: p,
    }));
  })();

  const cropOptions = getCropOptions(language, [CORN, SOYBEANS]);

  const reset = () => {
    setSelectedProduct(null);
    setSelectedSeed(null);
    setProductName('');
    setTrait('');
  };

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const handleProductTypeSelection = (_type: ComboboxItem) => {
    reset();
    setProductType(_type.value);
  };

  return (
    <Stack>
      <Header title={getString('addProducts', language)} divider="sm" order={3} />
      <Group justify="space-between" align="flex-end" grow>
        <Select
          data={REC_SET_PRODUCT_TYPES}
          label={getString('type', language)}
          onChange={(_value, option) => handleProductTypeSelection(option)}
          value={productType}
        />
        {productType === PRODUCT_TYPE_SEED ? (
          <Select
            data={cropOptions}
            label={getString('crop', language)}
            onChange={(c) => (c ? setCrop(c) : () => {})}
            value={crop}
          />
        ) : (
          <TextInput disabled readOnly />
        )}
        <Typeahead
          value={productName}
          onTextChange={handleProductNameChange}
          onSelect={handleDropdownSelection}
          onDeselect={reset}
          data={options}
          placeholder={getString('product', language)}
          leftSection={<FiSearch />}
          disabled={Boolean(productType === PRODUCT_TYPE_SEED ? selectedSeed : selectedProduct)}
        />
        <TextInput
          disabled={productType !== PRODUCT_TYPE_SEED}
          label={getString('trait', language)}
          onChange={() => {}}
          value={trait}
          readOnly
        />
        <Button
          color="#112F63"
          disabled={!(selectedProduct || selectedSeed) || modalOpened}
          onClick={openModal}
          radius="lg"
          variant="filled"
        >
          + {getString('addProduct', language)}
        </Button>
      </Group>
      <Space h="sm" />
      {modalOpened ? (
        <InputModal
          closeModal={handleCloseModal}
          isTemporary
          modalOpened={modalOpened}
          catalog={catalog}
          selectedProduct={selectedProduct}
          selectedSeed={selectedSeed}
        />
      ) : null}
    </Stack>
  );
};

export default ProductSearch;
