import {
  AA_RERUN,
  AMMONIUM_ACETATE,
  analysisDisplayNames,
  analysisShortNames,
  BRAY,
  chemistryAnalysisListExpanded,
  fullAnalysisOrderedList,
  GENOMIC,
  genomicAnalysisList,
  KCL,
  NITRATE_RERUN,
  labIcon,
  LOSS_ON_IGNITION,
  M3,
  M3_RERUN,
  MANUAL_RECORD,
  NITRATE,
  NUTRIENT,
  OLSEN,
  OM,
  OM_RERUN,
  PH,
  PH_RERUN,
  productAnalysisList,
  QPCR,
  rerunAnalysisList,
  SHOTGUN,
  SHOTGUN_QPCR,
  SUCCESSFUL as RERUN_SUCCESSFUL,
  analysisToRerunTypes,
  AMPLICON,
  SIKORA,
  MODIFIED_MEHLICH,
  chemistry590List,
  P_BRAY,
  K_BRAY,
  PHOSPHATE,
} from 'constants/analysis';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import {
  ANALYSIS_590,
  DECISION_DASHBOARD,
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
  ROOTWORM_PRESSURE_PANEL,
  SCN_PRESSURE_PANEL,
} from 'constants/products';
import {
  NORTHERN_CORN_ROOTWORM_ID,
  ROOTWORM_ID,
  ROOT_KNOT_NEMATODE_360_ID,
  SCN_ID,
  SHOTGUN_NORTHERN_CORN_ROOTWORM_ID,
  SHOTGUN_ROOTWORM_ID,
  SHOTGUN_WESTERN_CORN_ROOTWORM_ID,
  SOYBEAN_CYST_NEMATODE_360_ID,
  SOYBEAN_CYST_NEMATODE_RKN,
  SUDDEN_DEATH_SYNDROME_QPCR_ID,
  SUDDEN_DEATH_SYNDROME_SHOTGUN_ID,
  WESTERN_CORN_ROOTWORM_ID,
} from 'constants/results';
import { LabSampleType } from 'store/labSamples/types';
import { SamplePlanType } from 'store/samplePlans/types';
import { SampleType } from 'store/samples/types';
import { getString } from 'strings/translation';
import { getPlatingStatusForAnalysis } from 'util/plates';
import { hasProducts } from './product';
import { formatBigNumberNotation } from './numUtils';

export const getAnalysisDisplayName = (analysis: string, language: string) => {
  if (
    [
      GENOMIC,
      SHOTGUN_QPCR,
      SHOTGUN,
      NUTRIENT,
      NITRATE,
      AMMONIUM_ACETATE,
      MANUAL_RECORD,
      LOSS_ON_IGNITION,
      M3_RERUN,
      PH_RERUN,
      NITRATE_RERUN,
      AA_RERUN,
      OM_RERUN,
      AMPLICON,
      SIKORA,
      MODIFIED_MEHLICH,
      BRAY,
      OLSEN,
      PHOSPHATE,
    ].includes(analysis)
  ) {
    return getString(analysisDisplayNames[analysis], language);
  }

  return analysisDisplayNames[analysis];
};

export const getAnalysisShortName = (analysis: string, language: string) => {
  if (
    [
      GENOMIC,
      SHOTGUN_QPCR,
      SHOTGUN,
      NUTRIENT,
      NITRATE,
      MANUAL_RECORD,
      BRAY,
      OLSEN,
      M3_RERUN,
      PH_RERUN,
      NITRATE_RERUN,
      AA_RERUN,
      OM_RERUN,
      SIKORA,
      MODIFIED_MEHLICH,
    ].includes(analysis)
  ) {
    return getString(analysisShortNames[analysis], language);
  }

  return analysisShortNames[analysis];
};

export const isAnalysisRequired = (sample: LabSampleType, analysis: string) => {
  switch (analysis) {
    case SHOTGUN:
      return hasProducts(sample, PERFORMANCE_PANEL, DECISION_DASHBOARD);
    case QPCR:
      return hasProducts(sample, PRESSURE_PANEL, ROOTWORM_PRESSURE_PANEL, SCN_PRESSURE_PANEL);
    case NUTRIENT:
    case M3:
    case PH:
      return hasProducts(sample, NUTRIENT_PANEL);
    case OM:
    case LOSS_ON_IGNITION:
      return sample.nutrient_panel_analyze_om;
    case KCL:
    case NITRATE:
      return hasProducts(sample, NITRATE_PANEL);
    case AMMONIUM_ACETATE:
      return sample.aa_rerun_status;
    case M3_RERUN:
      return sample.m3_rerun_status;
    case PH_RERUN:
      return sample.ph_rerun_status;
    case NITRATE_RERUN:
      return sample.nitrate_rerun_status;
    case OM_RERUN:
      return sample.om_rerun_status;
    case AA_RERUN:
    default:
      return false;
  }
};

export const getRequiredAnalysisForProduct = (product: string) => {
  switch (product) {
    case PERFORMANCE_PANEL:
    case DECISION_DASHBOARD:
      return SHOTGUN;
    case PRESSURE_PANEL:
    case ROOTWORM_PRESSURE_PANEL:
    case SCN_PRESSURE_PANEL:
      return QPCR;
    case NUTRIENT_PANEL:
    case ANALYSIS_590:
      return NUTRIENT;
    case NITRATE_PANEL:
      return NITRATE;
    default:
      return null;
  }
};

export const getRequiredAnalysisForSample = (
  sample: LabSampleType,
  exhaustive?: boolean,
  reruns?: boolean,
  shouldShow590?: boolean,
) => {
  const analysisList = exhaustive
    ? [
        ...genomicAnalysisList,
        ...chemistryAnalysisListExpanded,
        ...(reruns ? rerunAnalysisList : []),
      ]
    : productAnalysisList;

  const requiredList = analysisList
    .map((a) => (a === KCL ? NITRATE : a))
    .filter((a) => isAnalysisRequired(sample, a));
  const listWith590 = shouldShow590 ? requiredList.concat(chemistry590List) : requiredList;
  return Array.from(new Set(listWith590)).sort(
    (a, b) => fullAnalysisOrderedList.indexOf(a) - fullAnalysisOrderedList.indexOf(b),
  );
};

export const isAnalysisComplete = (
  sample: LabSampleType | SampleType | SamplePlanType,
  analysis: string | null,
) => {
  switch (analysis) {
    case SHOTGUN:
      return sample.shotgun_processed;
    case QPCR:
      return sample.qpcr_processed;
    case NUTRIENT:
      return sample.chemistry_processed;
    case KCL:
    case NITRATE:
      return sample.nitrate_processed;
    case M3:
    case P_BRAY:
    case K_BRAY:
      return (sample as LabSampleType)?.m3_processed;
    case PH:
      return (sample as LabSampleType)?.ph_processed;
    case AMMONIUM_ACETATE:
      return (sample as LabSampleType)?.aa_processed;
    case LOSS_ON_IGNITION:
    case OM:
      return (sample as LabSampleType)?.om_processed;
    case M3_RERUN:
      return (sample as LabSampleType)?.m3_rerun_status === RERUN_SUCCESSFUL;
    case PH_RERUN:
      return (sample as LabSampleType)?.ph_rerun_status === RERUN_SUCCESSFUL;
    case NITRATE_RERUN:
      return (sample as LabSampleType)?.nitrate_rerun_status === RERUN_SUCCESSFUL;
    case AA_RERUN:
      return (sample as LabSampleType)?.aa_rerun_status === RERUN_SUCCESSFUL;
    case OM_RERUN:
      return (sample as LabSampleType)?.om_rerun_status === RERUN_SUCCESSFUL;
    default:
      return false;
  }
};

export const whichAnalysisComplete = (sample: SampleType, ...analyses: string[]) => {
  const complete: string[] = [];
  const incomplete: string[] = [];

  analyses.forEach(
    (analysis) => {
      if (isAnalysisComplete(sample, analysis)) {
        complete.push(analysis);
      } else {
        incomplete.push(analysis);
      }
    },
    {} as { [key: string]: string[] },
  );

  return {
    complete,
    incomplete,
  };
};

export const isNotAnalyzed = (sample: SampleType, analysis: string) => {
  switch (analysis) {
    case SHOTGUN:
      return sample.performance_panel_not_analyzed;
    case QPCR:
      return sample.pressure_panel_not_analyzed;
    case NUTRIENT:
    case M3:
    case KCL:
    case NITRATE:
    case PH:
    case AMMONIUM_ACETATE:
      return sample.nutrient_panel_not_analyzed;
    default:
      return false;
  }
};

export const getPendingRerunsForSample = (sample: LabSampleType, analysis: string) =>
  (analysisToRerunTypes[analysis] ?? []).filter(
    (rerun) => isAnalysisRequired(sample, rerun) && !isAnalysisComplete(sample, rerun),
  );

export const getProgressStageForAnalysis = (
  sample: LabSampleType,
  analysis: string,
  language: string,
) => {
  const pendingReruns = getPendingRerunsForSample(sample, analysis);
  if (pendingReruns.length) {
    return pendingReruns.map((rerun) => getAnalysisShortName(rerun, language)).join(',');
  }
  if (isAnalysisComplete(sample, analysis)) {
    return getString('complete', language);
  }
  if (analysis === AMMONIUM_ACETATE && !sample.ph && isAnalysisRequired(sample, NUTRIENT)) {
    return getString('tbd', language);
  }
  if (!isAnalysisRequired(sample, analysis)) {
    return NOT_APPLICABLE;
  }
  if (isNotAnalyzed(sample, analysis)) {
    return getString('notAnalyzed', language);
  }
  const plating = getPlatingStatusForAnalysis(sample, analysis, language);
  return plating || getString('pending', language);
};

export const getAnalysisMethodOptions = (analyses: string[], language: string) =>
  analyses.map((analysis, idx) => ({
    id: idx,
    displayName: getAnalysisShortName(analysis, language),
    label: getAnalysisDisplayName(analysis, language),
    value: analysis,
  }));

export const getLabIconForSample = (sample: LabSampleType) =>
  getRequiredAnalysisForSample(sample, false)
    .filter((analysis) => analysis !== NITRATE)
    .map((analysis) => labIcon[analysis])
    .join('');

export const getFilteredReadsString = (sample: LabSampleType, language: string) =>
  sample.total_filtered_reads
    ? `${formatBigNumberNotation(sample.total_filtered_reads)} ${getString('reads', language)}`
    : getString('noData', language);

export const getTrueFalseOptions = (language: string) => [
  { displayName: getString('on', language), label: getString('on', language), id: 1, value: true },
  {
    displayName: getString('off', language),
    label: getString('off', language),
    id: 0,
    value: false,
  },
];

export const getShotgunVersion = (analyticId: number) => {
  switch (analyticId) {
    case SUDDEN_DEATH_SYNDROME_QPCR_ID:
    case SUDDEN_DEATH_SYNDROME_SHOTGUN_ID:
      return SUDDEN_DEATH_SYNDROME_SHOTGUN_ID;
    case WESTERN_CORN_ROOTWORM_ID:
    case SHOTGUN_WESTERN_CORN_ROOTWORM_ID:
      return SHOTGUN_WESTERN_CORN_ROOTWORM_ID;
    case NORTHERN_CORN_ROOTWORM_ID:
    case SHOTGUN_NORTHERN_CORN_ROOTWORM_ID:
      return SHOTGUN_NORTHERN_CORN_ROOTWORM_ID;
    case ROOTWORM_ID:
    case SHOTGUN_ROOTWORM_ID:
      return SHOTGUN_ROOTWORM_ID;
    case SOYBEAN_CYST_NEMATODE_RKN:
    case ROOT_KNOT_NEMATODE_360_ID:
      return ROOT_KNOT_NEMATODE_360_ID;
    case SCN_ID:
    case SOYBEAN_CYST_NEMATODE_360_ID:
      return SOYBEAN_CYST_NEMATODE_360_ID;
    default:
      return null;
  }
};
