import {
  IN_FURROW,
  FOLIAR,
  SEED_TREATMENT,
  TRAITS,
  TREATMENT,
  OPEN_RISKS,
  SEED,
  LOW_COVERAGE,
  MODERATE_COVERAGE,
  NO_COVERAGE,
  HIGH_COVERAGE,
  CHART_INPUTS_SELCTION_MAP,
  getNoneProduct,
} from 'constants/cropPlan';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { HIGH_RISK, LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { NONE } from 'constants/products';
import { AnalyticSortOrderType, AnalyticType } from 'store/analytics/types';
import { CROP_PROTECTION, PATHOGENS } from 'constants/results';
import { CORN, allCrops, ALL } from 'constants/variables';
import { AgronomicProductType, CoverageRatingType } from 'store/cropPlans/types';
import { FieldType } from 'store/fields/types';
import { OperationFieldType } from 'store/operation/types';
import { CatalogType } from 'store/catalogs/types';
import { getString } from 'strings/translation';
import { remToPx } from './chartUtils';
import { sortByCreatedAt } from './date';
import { RISK_FILL_COLORS, CROP_PLAN_YELLOW } from './mapImageryColors';
import { getCategoryFromParams } from './results';
import { sortByStringArr } from './stringUtils';

export const getCropsInFields = (fields: FieldType[]) => {
  const crops = Array.from(new Set(fields.map((field) => getCropOrDefault(field))));
  return sortByStringArr(crops, allCrops);
};

export const getCropOrDefault = (field: FieldType | undefined) => {
  return getLatestCropPlan(field)?.crop || CORN;
};

export const getLatestCropPlan = (fieldGeometry: FieldType | undefined) => {
  return sortByCreatedAt(fieldGeometry?.features[0].properties.crop_plans || [])?.[0];
};

export const getCropPlanRowValues = (fieldGeometry: FieldType | undefined) => {
  const cropPlan = getLatestCropPlan(fieldGeometry);
  const seed = cropPlan?.seeds[0];
  const inFurrow =
    cropPlan?.agronomic_products.filter((product) => product.category === IN_FURROW) || [];
  const foliar =
    cropPlan?.agronomic_products.filter((product) => product.category === FOLIAR) || [];
  const treatment =
    cropPlan?.agronomic_products.filter((product) => product.category === SEED_TREATMENT) || [];
  const openRisks = cropPlan?.outstanding_risks.outstanding_risks;

  const concatenateNames = (products: AgronomicProductType[]) =>
    products.map((product) => product.name).join(', ');

  return [
    { label: SEED, value: seed?.hybrid || NOT_APPLICABLE },
    { label: TRAITS, value: seed?.traits.join(',') || NOT_APPLICABLE },
    { label: TREATMENT, value: concatenateNames(treatment) || NOT_APPLICABLE },
    { label: IN_FURROW, value: concatenateNames(inFurrow) || NOT_APPLICABLE },
    { label: FOLIAR, value: concatenateNames(foliar) || NOT_APPLICABLE },
    { label: OPEN_RISKS, value: openRisks !== undefined ? openRisks : NOT_APPLICABLE },
  ];
};

export const getChartAnalytics = (
  analysis: string | undefined,
  crop: string,
  analytics: AnalyticType[],
  analyticSortOrder: AnalyticSortOrderType,
) =>
  getCategoryFromParams(analysis) === PATHOGENS
    ? [...analyticSortOrder[CROP_PROTECTION][crop], ...analyticSortOrder[FOLIAR][crop]]
        .map((id) => ({
          ...analytics.find((analytic) => analytic.id === id),
        }))
        .filter((analytic) => Boolean(analytic.id))
    : [];

// Placeholder until data comes from BE
export const getCropPlanSummarySections = (language: string) => {
  return [
    {
      title: `${getString(SEED, language)} / ${getString(TRAITS, language)} / ${getString(TREATMENT, language)}`,
    },
    {
      title: getString('fertility', language),
    },
    {
      title: `${getString(IN_FURROW, language)} ${getString(CROP_PROTECTION, language)}`,
    },
    {
      title: `${getString(FOLIAR, language)} ${getString(CROP_PROTECTION, language)}`,
    },
  ];
};

// Placeholder until data comes from BE
export const getCropPlanSummaryHeaders = (language: string) => {
  return [
    getString('acres', language),
    getString('avgRate', language),
    getString('units', language),
  ];
};

export const getRiskLevel = (riskLevel: number | undefined) => {
  if (!riskLevel) {
    return NONE;
  }
  if (riskLevel <= LOW_COVERAGE) {
    return LOW_RISK;
  }
  if (riskLevel <= MODERATE_COVERAGE) {
    return MODERATE_RISK;
  }
  return HIGH_RISK;
};

export const getRatingText = (riskLevel: number | undefined, language: string) => {
  if (!riskLevel) {
    return 'N/A';
  }
  if (riskLevel <= LOW_COVERAGE) {
    return getString('low', language);
  }
  if (riskLevel <= MODERATE_COVERAGE) {
    return getString('good', language);
  }
  return getString('exc', language);
};

export const getRatingColor = (riskLevel: number | undefined) => {
  if (!riskLevel) {
    return 'transparent';
  }
  if (riskLevel <= LOW_COVERAGE) {
    return CROP_PLAN_YELLOW;
  }
  if (riskLevel <= MODERATE_COVERAGE) {
    return RISK_FILL_COLORS.MODERATE_RISK;
  }
  return RISK_FILL_COLORS.LOW_RISK;
};

export const getLowModHigh = (language: string, nonNull = false) => {
  const baseData = [
    { label: getString('none', language), value: String(NO_COVERAGE) },
    { label: getString('low', language), value: String(LOW_COVERAGE) },
    { label: getString('mod', language), value: String(MODERATE_COVERAGE) },
    { label: getString('high', language), value: String(HIGH_COVERAGE) },
  ];
  return nonNull ? baseData.slice(1) : baseData;
};

export const getCatalogSeedsProducts = (
  catalog: CatalogType | null,
  cropType: string,
  selectedChartVal: string | undefined,
  isSeed: boolean,
  language: string,
) => {
  if (!selectedChartVal || !catalog) {
    return [];
  }
  return isSeed
    ? Object.values(catalog?.seeds || [])
        .filter((catSeed) => catSeed.seed_metadata?.crop === cropType)
        .sort((a, b) => a.seed_metadata?.hybrid.localeCompare(b.seed_metadata?.hybrid) || 0)
    : Object.values(catalog?.products || [])
        .filter(
          (catProduct) =>
            catProduct.product_metadata.category &&
            CHART_INPUTS_SELCTION_MAP[catProduct.product_metadata.category].includes(
              selectedChartVal,
            ) &&
            [ALL, cropType].includes(catProduct.product_metadata.crop),
        )
        .sort((a, b) => a.product_metadata.name.localeCompare(b.product_metadata?.name) || 0)
        .concat(getNoneProduct(language));
};

export const getChartPixelHeight = (isChart: boolean, recCount: number) => {
  if (isChart) {
    return remToPx(recCount ? recCount + 4 : 3);
  }
  return remToPx(recCount ? recCount + 7 : 7);
};

export const sortByOutstandingRisks = (
  fields: OperationFieldType[],
  fieldGeoms: FieldType[],
  cropType: string,
): OperationFieldType[] => {
  const fieldsWithRisks = fields.reduce(
    (acc, field) => {
      const fieldGeom = fieldGeoms.find((geom) => field.id === geom.features[0].properties.id);
      const hasOpenRisks = !!getLatestCropPlan(fieldGeom)?.outstanding_risks.outstanding_risks;
      if (getCropOrDefault(fieldGeom) !== cropType) {
        return acc;
      }
      return {
        ...acc,
        [hasOpenRisks ? OPEN_RISKS : NONE]: [...acc[hasOpenRisks ? OPEN_RISKS : NONE], field],
      };
    },
    { [OPEN_RISKS]: [], [NONE]: [] },
  );
  return [...fieldsWithRisks[OPEN_RISKS], ...fieldsWithRisks[NONE]];
};

export const sortInputsByCoverage = (
  inputs: {
    id: number;
    displayName?: string;
    label: string;
    fw: number;
    coverage_ratings: CoverageRatingType[];
  }[],
) =>
  inputs.sort((a, b) => {
    const inputARatings = a.coverage_ratings;
    const inputBRatings = b.coverage_ratings;
    if (inputARatings.length > inputBRatings.length) {
      return -1;
    }
    return inputBRatings.length > inputARatings.length ? 1 : 0;
  });

export const getCropOptions = (language: string, cropsList: string[] = allCrops) =>
  cropsList.map((crop, idx) => ({
    id: idx,
    label: getString(crop, language),
    value: crop,
  }));
