import { handleJsonResponse, requestGet, requestPost, requestPut, SERVICE_URL } from 'util/request';
import { CatalogType } from './types';

export const requestGetCatalogs = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/catalogs`));

export const requestGetCatalog = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/catalog/${id}`));

export const requestPostCatalog = async (body: Partial<CatalogType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/catalog/`, { body }));

export const requestPutCatalog = async (id: number, body: Partial<CatalogType>) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/catalog/${id}`, { body }));
