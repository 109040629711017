import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Center, Title, Space, Divider, ComboboxItem, Select } from '@mantine/core';
import { AgronomicProductType, CombinedAnalyticType, SeedType } from 'store/cropPlans/types';
import { CatalogType } from 'store/catalogs/types';
import setToast from 'actions/toastActions';
import { requestCatalogAssignProduct, requestCatalogAssignSeed } from 'store/cropPlans/requests';
import { RootState } from 'store';
import { receiveSingleCatalog } from 'store/catalogs/actions';
import { FOLIAR, IN_FURROW, SEED_TREATMENT } from 'constants/cropPlan';
import { ALL, CORN, SOYBEANS } from 'constants/variables';

import SeedProtection from '../SeedProtection';
import ProductProtection from '../ProductProtection';
import { getAllCatalogs } from 'store/catalogs/thunks';

type InputRatingLookup = { [id: number]: CombinedAnalyticType };

interface InputModalProps {
  closeModal: VoidFunction;
  defaultCategory?: string;
  defaultProductRatings?: InputRatingLookup;
  defaultSeedRatings?: InputRatingLookup;
  isTemporary: boolean;
  modalOpened: boolean;
  catalog: Partial<CatalogType> | null;
  selectedProduct: AgronomicProductType | null;
  selectedSeed: SeedType | null;
}

const InputModal = ({
  closeModal,
  defaultCategory = '',
  defaultProductRatings = {},
  defaultSeedRatings = {},
  isTemporary = false,
  modalOpened,
  catalog,
  selectedProduct,
  selectedSeed,
}: InputModalProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryOptions = [
    {
      label: 'Foliar',
      value: FOLIAR,
    },
    {
      label: 'In Furrow',
      value: IN_FURROW,
    },
    {
      label: 'Seed Treatment',
      value: SEED_TREATMENT,
    },
  ];
  const [category, setCategory] = useState<ComboboxItem>(
    categoryOptions.find((option) => option.value === defaultCategory) || categoryOptions[0],
  );
  const cropOptions = [
    {
      label: 'All',
      value: ALL,
    },
    {
      label: 'Corn',
      value: CORN,
    },
    {
      label: 'Soybeans',
      value: SOYBEANS,
    },
  ];
  const [crop, setCrop] = useState<ComboboxItem>(
    cropOptions.find((option) => option.value === selectedProduct?.crop) || cropOptions[0],
  );

  const { analytics, pestGroups } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
    pestGroups: state.cropPlanning.pestGroups,
  }));

  const [seedRatings, setSeedRatings] = useState<{ [id: number]: CombinedAnalyticType }>(
    defaultSeedRatings,
  );
  const [productRatings, setProductRatings] = useState<{ [id: number]: CombinedAnalyticType }>(
    defaultProductRatings,
  );
  const [loading, setIsLoading] = useState(false);
  const setToastMessage = (message: string, type?: string, time?: number) =>
    dispatch(setToast(message, type, time));

  useEffect(() => {
    if (!Object.keys(seedRatings).length) {
      setSeedRatings(
        analytics.reduce((all: { [key: number]: CombinedAnalyticType }, analytic) => {
          const analyticInCoverage = selectedSeed?.coverage_ratings.find(
            (rating) => rating.analytic_id === analytic.id,
          );
          if (analyticInCoverage) {
            return { ...all, [analytic.id]: { ...analytic, ...analyticInCoverage } };
          }
          return { ...all, [analytic.id]: { ...analytic, coverage_rating: 0 } };
        }, {}),
      );
    }
  }, [selectedSeed, seedRatings, analytics]);

  useEffect(() => {
    if (!Object.keys(productRatings).length) {
      setProductRatings(
        analytics.reduce((all: { [key: number]: CombinedAnalyticType }, analytic) => {
          const analyticInCoverage = selectedProduct?.coverage_ratings.find(
            (rating) => rating.analytic_id === analytic.id,
          );
          if (analyticInCoverage) {
            return { ...all, [analytic.id]: { ...analytic, ...analyticInCoverage } };
          }
          return {
            ...all,
            [analytic.id]: {
              ...analytic,
              coverage_rating: 0,
            },
          };
        }, {}),
      );
    }
  }, [productRatings, analytics]);

  if (!(pestGroups && (selectedProduct || selectedSeed))) {
    return null;
  }

  const setInputRatingsSeed = (
    input: SeedType | AgronomicProductType,
    analyticId: number,
    coverage_rating: number,
  ) => {
    setSeedRatings((ratings) => {
      const newRatings = { ...ratings };
      newRatings[analyticId] = { ...newRatings[analyticId], coverage_rating };
      return newRatings;
    });
  };

  const setInputRatingsProduct = (
    input: SeedType | AgronomicProductType,
    analyticId: number,
    coverage_rating: number,
  ) => {
    setProductRatings((ratings) => {
      const newRatings = { ...ratings };
      newRatings[analyticId] = { ...newRatings[analyticId], coverage_rating };
      return newRatings;
    });
  };

  const handleAddInput = async () => {
    try {
      setIsLoading(true);
      const isNewCatalog = catalog?.id !== undefined;
      if (selectedSeed) {
        const values = Object.values(seedRatings).map((rating) => [
          selectedSeed.id,
          rating.id,
          Number(rating.coverage_rating),
        ]);
        const response = await requestCatalogAssignSeed(
          values,
          catalog?.id,
          selectedProduct ? category.value : undefined,
          undefined,
        );
        setToastMessage(`Seed ${isTemporary ? 'added' : 'saved'} successfully!`);
        dispatch(receiveSingleCatalog(response));
        if (!isNewCatalog) {
          dispatch(getAllCatalogs());
          navigate(`/catalog/${response.id}`);
        }
      } else if (selectedProduct) {
        const values = Object.values(productRatings).map((rating) => [
          selectedProduct.id,
          rating.id,
          Number(rating.coverage_rating),
        ]);
        const response = await requestCatalogAssignProduct(
          values,
          catalog?.id,
          selectedProduct ? category.value : undefined,
          selectedProduct ? crop.value : undefined,
        );
        setToastMessage(`Product ${isTemporary ? 'added' : 'saved'} successfully!`);
        dispatch(receiveSingleCatalog(response));
        if (!isNewCatalog) {
          dispatch(getAllCatalogs());
          navigate(`/catalog/${response.id}`);
        }
      }
    } catch (e) {
      setToastMessage("Couldn't add product. Please refresh and try again.", 'error');
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const inputName = selectedSeed?.hybrid || selectedProduct?.name;
  const modalSize = selectedSeed ? '75%' : '85%';

  return (
    <Modal
      onClose={closeModal}
      opened={modalOpened}
      size={modalSize}
      title={`${isTemporary ? 'Add' : 'Edit'} ${selectedProduct ? 'Product' : 'Seed'} - ${inputName}`}
    >
      {Boolean(selectedProduct) && (
        <>
          <Select
            data={categoryOptions}
            disabled={Boolean(defaultCategory)}
            label="Category"
            value={category.value}
            onChange={(_value, option) => setCategory(option)}
          />
          <Space h="md" />
        </>
      )}
      {Boolean(selectedProduct) && (
        <>
          <Select
            data={cropOptions}
            label="Crop"
            value={crop.value}
            onChange={(_value, option) => setCrop(option)}
          />
          <Space h="md" />
        </>
      )}
      <Title order={6}>Coverage Ratings</Title>
      <Space h="md" />
      {catalog && selectedSeed && (
        <SeedProtection
          analytics={seedRatings}
          pestGroups={pestGroups}
          catalog={catalog}
          seed={selectedSeed}
          setInputRating={setInputRatingsSeed}
          isTemporary
        />
      )}
      {catalog && selectedProduct && (
        <ProductProtection
          analytics={productRatings}
          pestGroups={pestGroups}
          product={selectedProduct}
          catalog={catalog}
          setInputRating={setInputRatingsProduct}
          isTemporary
        />
      )}
      <Space h="md" />
      <Divider />
      <Space h="md" />
      <Center>
        <Button disabled={loading} onClick={handleAddInput}>
          Save
        </Button>
      </Center>
    </Modal>
  );
};

export default InputModal;
