import React, { FunctionComponent, ReactNode, useMemo, useEffect } from 'react';

import { getString } from 'strings/translation';
import Plus from 'images/icons/plus.png';
import useBrowserLanguage from 'util/hooks/useLanguage';
import styles from './PrintHeaderFooter.module.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getIsPatternSite, hostNameLogoParse } from 'util/auth';
import { Box, Group, Stack, Text } from '@mantine/core';

interface FooterProps {
  children: ReactNode;
}

type paramsType = {
  operationId: string;
};

const PrintHeaderFooter: FunctionComponent<FooterProps> = ({ children }) => {
  const operationId = Number(useParams<paramsType>().operationId);
  const language = useBrowserLanguage();
  const [logo, setLogo] = React.useState<string | null>(null);
  const isPattern = getIsPatternSite();

  const { agencies, operation } = useSelector((state: RootState) => ({
    agencies: state.agencies.allAgencies,
    operation: state.operations.operationsById[operationId],
  }));

  useEffect(() => {
    const logoFromUrl = hostNameLogoParse();
    if (logoFromUrl) {
      setLogo(logoFromUrl);
    }
  }, []);

  const agencyLogo = useMemo(() => {
    const findAgencyByOperationBillingUser = agencies?.find(
      (agency) => operation?.billing_user_id === agency.primary_user.id,
    );
    return findAgencyByOperationBillingUser?.agency_logo_url;
  }, [agencies, operation]);

  return (
    <Box className={styles.Body}>
      <Group
        align="center"
        justify="space-between"
        gap="sm"
        style={{ borderBottom: '2px solid #ececed' }}
        className={styles.PrintOnly}
        c="#0f3162"
        pb="sm"
        ta="center"
      >
        <Group align="center" gap="sm" mr="auto" flex={1}>
          {logo && (
            <img
              alt="Pattern Logo"
              className={styles.Logo}
              data-test-id="pattern-logo-print"
              src={logo}
            />
          )}
          {isPattern && agencyLogo && (
            <>
              <img alt="Plus" style={{ width: 13, height: 14 }} src={Plus} />
              <img alt="Agency Logo" className={styles.Logo} src={agencyLogo} />
            </>
          )}
        </Group>
        <Stack gap={0}>
          <Text size="lg" fw={600} fs="italic" mx="auto" my={0} lh="sm" fz="h5">
            {getString('printHeader', language)}
          </Text>
          <Text component="a" href="https://www.pattern.ag" target="_blank" fz="sm">
            www.pattern.ag
          </Text>
        </Stack>
        <div style={{ marginLeft: 'auto', flex: 1 }} />
      </Group>
      {children}
    </Box>
  );
};

export default PrintHeaderFooter;
