import { AgronomicProductType, CoverageRatingType, SeedType } from 'store/cropPlans/types';

export const RECEIVE_CATALOGS = 'RECEIVE_CATALOGS';
export const RECEIVE_CATALOG = 'RECEIVE_CATALOG';
export const REQUEST_CATALOGS = 'REQUEST_CATALOGS';
export const CATALOG_REQUEST_ERROR = 'CATALOG_REQUEST_ERROR';

export type CatalogSeedType = {
  ratings: CoverageRatingType[];
  seed_metadata: SeedType;
};

export type CatalogProductType = {
  ratings: CoverageRatingType[];
  product_metadata: AgronomicProductType;
};

export type CatalogType = {
  id: number;
  name: string;
  created_by_id: number;
  created_by_name: string;
  show_in_ui: boolean;
  agency_id: number | null;
  agency: {
    id: number;
    name: string;
  } | null;
  seeds: { [id: number]: CatalogSeedType };
  products: {
    [id: number]: CatalogProductType;
  };
};

type RequestCatalogType = {
  type: typeof REQUEST_CATALOGS;
};

type ReceiveCatalogsType = {
  type: typeof RECEIVE_CATALOGS;
  payload: {
    catalogs: CatalogType[];
  };
};

type ReceiveSingleCatalogType = {
  type: typeof RECEIVE_CATALOG;
  payload: {
    catalog: CatalogType;
  };
};

type CatalogRequestError = {
  type: typeof CATALOG_REQUEST_ERROR;
};

export type CatalogActionsType =
  | ReceiveCatalogsType
  | ReceiveSingleCatalogType
  | RequestCatalogType
  | CatalogRequestError;
