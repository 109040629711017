import { AGRONOMIC_PRODUCTS, SEEDS } from 'constants/cropPlan';
import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { CropPlanType } from './types';

export const requestPostCropPlan = async (body: Partial<CropPlanType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/crop_plan/`, { body }));

export const requestPutCropPlan = async (cropPlanId: number, body: Partial<CropPlanType>) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/crop_plan/${cropPlanId}`, { body }));

export const requestGetPestGroups = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/crop_plan_pest_groups/`));

const makeSearchFunction =
  (searchType: typeof SEEDS | typeof AGRONOMIC_PRODUCTS) => async (name: string, crop: string) => {
    const response = await requestGet(`${SERVICE_URL}/${searchType}?name=${name}&crop=${crop}`);
    return handleJsonResponse(response);
  };

export const requestGetSeedSearch = makeSearchFunction(SEEDS);
export const requestGetProductSearch = makeSearchFunction(AGRONOMIC_PRODUCTS);

const makeCatalogAssignFunction =
  (searchType: typeof SEEDS | typeof AGRONOMIC_PRODUCTS) =>
  async (
    values: number[][],
    catalogId?: number,
    category?: string,
    crop?: string,
    agencyId?: number,
  ) => {
    const body = {
      [searchType === SEEDS ? 'seed_coverage_ratings' : 'product_coverage_ratings']: values.map(
        (value) => ({
          [searchType === SEEDS ? 'seed_id' : 'product_id']: value[0],
          analytic_id: value[1],
          coverage_ratings: [value[2]],
          category,
          crop,
          agencyId,
        }),
      ),
    };
    const response = await requestPut(`${SERVICE_URL}/catalog_inputs/${catalogId || ''}`, {
      body,
    });
    return handleJsonResponse(response);
  };

export const requestCatalogAssignSeed = makeCatalogAssignFunction(SEEDS);
export const requestCatalogAssignProduct = makeCatalogAssignFunction(AGRONOMIC_PRODUCTS);

const makeCatalogRemoveFunction =
  (type: typeof SEEDS | typeof AGRONOMIC_PRODUCTS) =>
  async (ids: number[], catalogId?: number, agencyId?: number) => {
    const body = {
      [type === SEEDS ? 'seed_ids' : 'product_ids']: ids,
      agencyId,
    };
    const response = await requestDelete(`${SERVICE_URL}/catalog_inputs/${catalogId || ''}`, {
      body,
    });
    return handleJsonResponse(response);
  };

export const requestCatalogRemoveSeed = makeCatalogRemoveFunction(SEEDS);
export const requestCatalogRemoveProduct = makeCatalogRemoveFunction(AGRONOMIC_PRODUCTS);

export const requestPutAssignInput = async (
  cropPlanId: number,
  body: { seed_ids?: (number | null)[][]; agronomic_products?: (number | null)[][] },
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/crop_plan_assign_inputs/${cropPlanId}`, { body }),
  );

export const requestDeleteAssignInput = async (
  cropPlanId: number,
  body: { seed_ids?: number[]; agronomic_products?: number[] },
) =>
  handleJsonResponse(
    await requestDelete(`${SERVICE_URL}/crop_plan_assign_inputs/${cropPlanId}`, { body }),
  );

export const requestOptimizeCropPlans = async (cropPlanIds: number[]) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/optimize_crop_plans/`, {
      body: { crop_plan_ids: cropPlanIds },
    }),
  );
